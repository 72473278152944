import settings from './apiSettings';

export function CreateEvent(token, title, isPublic){
    return fetch(`${settings.host}/Events`, {
        method: 'POST',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify({'title':title, 'public':isPublic})
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}

export function DeleteEvent(token, eventStringIdentifier){
    return fetch(`${settings.host}/Events/${eventStringIdentifier}`, {
        method: 'DELETE',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        }
    })
}

export function GetEvents(token){
    return fetch(`${settings.host}/Events`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    }) 
}

export function GetEventById(token,eventId){
    return fetch(`${settings.host}/Events/${eventId}`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}

export function GetBeersForEvents(token, eventId){
    return fetch(`${settings.host}/Events/${eventId}/beers`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}

export function AddBeerToEvent(token, eventStringIdentifier, beerId){
    return fetch(`${settings.host}/Events/${eventStringIdentifier}/beers/${beerId}`, {
        method: 'POST',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } 
    })
}

export function RemoveBeerFromEvent(token, eventId, beerId){
    return fetch(`${settings.host}/Events/${eventId}/beers/${beerId}`, {
        method: 'DELETE',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } 
    })
}

export function GetReviewsForEvent(token, eventId, beerId){
    return fetch(`${settings.host}/Events/${eventId}/reviews`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}

export function ReorderBeers(token, eventId, beers){
    return fetch(`${settings.host}/Events/${eventId}/beers`, {
        method: 'PATCH',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify({'EventIdentifier':eventId, 'NewOrderBeers': beers})
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}

export function GetEventStatistics(token, eventId){
    return fetch(`${settings.host}/Events/${eventId}/statistics`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}