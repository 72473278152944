import { Grid, Paper, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@mui/styles";
import { GetEventById, } from '../../Api/EventApi';
import Tab from '@mui/material/Tab';
import {QRCodeSVG} from 'qrcode.react';
import {  Typography } from '@mui/material';
import EventReviewList from "./EventReviewList";
import EventAdministration from "./EventAdministration";
import EventStatistics from "./EventStatistics";
import {TabPanel, a11yProps} from "../../Components/TabPanel";
import EventBeerList from "./EventBeerList";
import { GetIdentityDescriptor } from "../../Api/AuthenticationApi";

const host = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://smakkid.is';

const useStyles = makeStyles({
    paper: {
        padding: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added shadow
        transition: 'all 0.3s ease'               // Smooth transitions
    },
    activeTab: {
        backgroundColor: '#AF000070',
        borderRadius: "20px 20px 00px 0px"
    }
});

const EventProfile = () => {
    const classes = useStyles();
    const { id } = useParams();
    const token = useRecoilValue(UserState);

    const [eventData, setEvent] = useState(null);
    const [identity, setIdentity] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (token) {
            GetIdentityDescriptor(token).then(setIdentity);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            GetEventById(token, id).then(setEvent);
        }
    }, [id, token]);

    const handleChange = (event, newValue) => setTabValue(newValue);
    const currentUserIsAdmin = identity?.id === eventData?.ownerId;

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
                <Paper className={classes.paper}>
                    {eventData ? (
                        <>
                            <Typography variant="h5">{eventData.title}</Typography>
                            <Typography variant="h6">{eventData.stringIdentifier}</Typography>
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Paper>
            </Grid>
            <Grid item xs={10}>
                <Paper className={classes.paper}>
                    {eventData ? <QRCodeSVG value={`${host}/events/${eventData.stringIdentifier}`} /> : <CircularProgress />}
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Tabs value={tabValue} onChange={handleChange} variant="fullWidth" color="secondary">
                    <Tab className={tabValue === 0 ? classes.activeTab : ''} label="Beers" {...a11yProps(0)} />
                    <Tab className={tabValue === 1 ? classes.activeTab : ''} label="Reviews" {...a11yProps(1)} />
                    <Tab className={tabValue === 2 ? classes.activeTab : ''} label="Statistics" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {currentUserIsAdmin ? <EventAdministration eventId={id} /> : <EventBeerList eventId={id} />}
                </TabPanel>
                <TabPanel value={tabValue} index={1}><EventReviewList eventId={id} /></TabPanel>
                <TabPanel value={tabValue} index={2}><EventStatistics eventId={id} /></TabPanel>
            </Grid>
        </Grid>
    );
};

export default EventProfile;