import { Button, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { GetBeersForEvents } from "../../Api/EventApi";
import UserState from "../../Atoms/UserAtom";
import { GetIdentityDescriptor } from "../../Api/AuthenticationApi";

const eventSort = (a, b) => a.order - b.order;

const EventBeerList = ({ eventId}) => {
    
    const [beers, setBeers] = useState(null);
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);

    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
          console.log(result);
          
          setIdentity(result)
        })
    }, [token]);


    useEffect(() => {
        console.log(identity);
        if (!identity || !identity.isAdministrator || !token) return;
        GetBeersForEvents(token, eventId).then(result => {
            result.sort(eventSort);
            setBeers(result);
        }).catch(error=>{
    
        });
    }, [eventId, token, identity]);

    return (
        <Grid container spacing={2} justifyContent="center" direction="column" style={{marginTop: "10px"}}>
            
            

            {beers?.map((beer) => (
                 <Grid item xs={10} key={`${beer.id}`}style={{marginBottom:"10px"}}>
                    <Paper>
                        <Grid item xs={12} container justifyContent="space-evenly" spacing={2} >
                            <Grid item xs={8}>
                                <Typography variant="caption">{beer.name}</Typography>
                            </Grid>
                            <Grid item  xs={2}>
                                <Button fullWidth size='small' style={{margin: "auto"}} variant="contained" href={`/event/${eventId}/review/${beer.id}`}>Review</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}

        </Grid>

    )
}
export default EventBeerList;