import { Fade, Grid, IconButton, Paper, Tooltip, Typography, CircularProgress, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { GetGroupFeed, GetGroupInfo, GetGroupMembers, KickUserFromGroup, LeaveGroup } from "../../Api/GroupApi";
import UserState from "../../Atoms/UserAtom";
import ReviewList from '../../Components/ReviewList';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { makeStyles } from "@mui/styles";
import GroupProfileAdministrationPanel from "../../Components/GroupProfileAdministrationPanel";
import { GetIdentityDescriptor } from "../../Api/AuthenticationApi";
import { ExpandCircleDown } from "@mui/icons-material";

const col = 'black'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText, // Assuming you want a contrasting text color
    },
    hoverEffect: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
    },
    coverImageContainer: {
        width: '100%',
        height: 250,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: 15,
    },
    groupImage: {
        width: 150,
        height: 150,
        marginBottom: 15,
    },
    adminSection: {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        marginBottom: 10,
    },
    groupName: {
        zIndex:23000,
        padding: theme.spacing(2),
        color: 'white',
        position: 'relative',
        left: 10,  // Adjust as needed for positioning

        fontWeight: 'bold',
        width: '100%',
        textShadow: `
        -1px 1px 1px ${col}, 
        1px 1px 1px ${col},
        1px -1px 1px ${col}, 
        -1px -1px 1px ${col}`
    },
}));

const GroupProfile = () => {
    const { groupId } = useParams();
    const classes = useStyles();
    const token = useRecoilValue(UserState);
    const [members, setMembers] = useState(null);
    const [feed, setFeed] = useState(null);
    const [group, setGroup] = useState(null);
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false);
    const [identity, setIdentity] = useState(null);
    
    useEffect(() => {
        if (!token) return;
        GetIdentityDescriptor(token).then(result => setIdentity(result));
    }, [token]);
    
    useEffect(() => {
        if (!token || !identity) return;
        
        const fetchData = async () => {
            try {
                const [feedData, membersData, groupInfo] = await Promise.all([
                    GetGroupFeed(token, groupId),
                    GetGroupMembers(token, groupId),
                    GetGroupInfo(token, groupId)
                ]);

                setFeed(feedData);
                setGroup(groupInfo);

                const userInGroup = membersData.find(u => u.id === identity.id);
                console.log(userInGroup);
                setCurrentUserIsAdmin(userInGroup?.isAdministrator || false);
                setMembers(membersData);
            } catch (error) {
                console.error("Failed to fetch data", error);
            }
        };

        fetchData();
    }, [groupId, token, identity]);

    const handleLeave = () => {
        LeaveGroup(token, groupId)
            .then(() => window.location.navigate('/'))
            .catch(error => console.log('caught a leave error...'));
    };

    const kickUser = (userId) => {
        KickUserFromGroup(token, groupId, userId)
            .then(() => window.location.refresh())
            .catch(error => console.log("Error kicking user:", error));
    };

    const RenderGroupMember = (member) => (
        <Fade in timeout={500} key={`${member.id}${member.username}`}>
            <Grid item container xs={12} className={classes.hoverEffect}>
                <Grid item xs={6}>{member.username}</Grid>
                <Grid item xs={2}>
                    {(currentUserIsAdmin || member.id === identity.id) && (
                        <Tooltip title={member.id === identity.id ? "Leave Group" : "Kick Member"}>
                            <IconButton onClick={() => (member.id === identity.id ? handleLeave : kickUser)(member.id)}>
                                <PersonRemoveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Fade>
    );


                        
    console.log(group);
    // {id: 1, ownerId: 3, title: 'Something!', b64CoverImage: null, members: null}
    return (
        <Grid container spacing={2}>
            {group ? (
                <Grid item xs={12}>
                    <Box
                        className={classes.coverImageContainer}
                        style={{ backgroundImage: `url(${group.b64CoverImage || 'DEFAULT_IMAGE_URL'})` }}
                    >
                        <Typography variant="h1" className={classes.groupName}>{group.title}</Typography>
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12} container justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}


            <Grid item xs={12} container spacing={2}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        {members ? (
                            <Grid item xs={12} container spacing={2}>
                                <Typography align="center" variant="h6">Administrators</Typography>
                                {members.filter(m => m.isAdministrator).map(RenderGroupMember)}
                                <Typography align="center" variant="h6">Members</Typography>
                                {members.filter(m => !m.isAdministrator).map(RenderGroupMember)}



                                
                            </Grid>


                        ) : (
                            <CircularProgress />
                        )}
                    </Paper>

                    {currentUserIsAdmin && (
                        <Paper>
                        <Accordion style={{ marginTop: '10px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandCircleDown />} aria-controls="panel-content" id="admin-panel-header">
                                <Typography>Administration</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GroupProfileAdministrationPanel group={group} />
                            </AccordionDetails>
                        </Accordion>
                        </Paper>
                    )}



                </Grid>
                <Grid item xs={8}>
                    {feed ? <ReviewList reviews={feed} displayBeerName /> : <CircularProgress />}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GroupProfile;