
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VerifyUser } from '../../Api/AuthenticationApi';

const useFetch = (token, setSuccess, setError)=>{useEffect(() => {
    VerifyUser(token).then(()=>{
        setSuccess(true);
        setTimeout(()=>{
            window.location = '/login'
        }, 5000)
    }).catch((error)=>{
        console.log("error in verification");
        console.log(error);
    })
}, [token, setSuccess, setError])}

function Verify() {

    
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const {token} = useParams();

    useFetch(token, setSuccess, setError);

    return (
        <Grid className="">
            
            { (!success && !error) &&
                <Typography>
                    You are being verified: {token}
                </Typography>
            }
            {success && <Grid>
                <Typography>Congratulations, you can now log in using your email and password.</Typography>
            </Grid>}
            {error &&
                <Grid><Typography>There was some error verifying the user.</Typography> </Grid>
            }
        </Grid>
    );
}

export default Verify;
