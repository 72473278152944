import { Autocomplete, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import {GetBeerList} from '../Api/BeerApi'
import { useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {AlphabeticalBeerSort} from '../Helpers/AlphabeticalSort';
import { Navigate } from 'react-router-dom'
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import InedxStats from '../Components/InfoComponents/IndexStats';
import Reccomendations from '../Components/InfoComponents/Reccomendations';

const useFetch = (setBeers)=>{useEffect(() => {
    GetBeerList().then(result=>{

        result.sort(AlphabeticalBeerSort);
        setBeers(result);
    }).catch(error=>{

    });
}, [setBeers])}

const useStyles = makeStyles((theme) => ({
    indexRoot: {
        margin: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    searchContainer: {
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main,
        width: '100%',
        height: '100px',
        maxWidth: '80%',
        padding: theme.spacing(1, 2),
        boxShadow: theme.shadows[3],
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    autocomplete: {
        color: `${theme.palette.primary.contrastText} !important`,
        flexGrow: 1,
        '& .MuiInputBase-root': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiInputLabel-root': {
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiAutocomplete-inputRoot': {
            color: `${theme.palette.primary.contrastText} !important`,
            '&.Mui-focused': {
                color: `${theme.palette.primary.contrastText} !important`,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${theme.palette.primary.contrastText} !important`
                },
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.contrastText} !important`
        }
    },

    searchIconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '& svg': {
            color: `${theme.palette.primary.contrastText} !important`,
        },
    },
    countdownContainer: {
        marginTop: theme.spacing(5),
    }
}));

 
function Index() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [beers, setBeers] = useState(null);
    const [selection, setSelection] = useState(null);
    useFetch(setBeers);

    return (
        <Grid container justifyContent='center' alignItems='center' direction='column' spacing={3}>
            
            <Grid item xs={12} md={8}  >
                <Typography align='center' variant='h1'>It's almost Christmas beer season!</Typography>
            </Grid>
            
            <Grid item xs={12} className={classes.indexRoot}>

            {!beers && <CircularProgress color="primary" /> }

            {beers &&<>
                {selection && <Navigate to={`/beer/${selection.id}`} />}
                <div className={classes.searchContainer}>
                    <div className={classes.searchIconContainer}>
                        <IconButton disabled>
                            <SearchIcon />
                        </IconButton>
                    </div>
                    <Autocomplete
                        className={classes.autocomplete}
                        freeSolo
                        options={beers}
                        getOptionLabel={(beer) => beer.name}
                        onChange={(event, newInputValue) => setSelection(newInputValue)}
                        renderInput={(params) => <TextField {...params} label={t("searchForBeer")} fullWidth />}
                        />
                </div>
            </>
            }
            </Grid>

            {/* <Grid item xs={12} md={8} style={{marginTop: '40px'}}>
                <Typography align="center" variant="h2">{t('welcomeMessage')}</Typography>
            </Grid> */}
            
            <Grid item xs={12} md={8}>
                <Reccomendations />
            </Grid>
            
            
            <Grid item xs={12}>
                
                {!beers && <CircularProgress color="primary" /> }
                {beers &&  <InedxStats beers={beers}/>}
            </Grid>
        </Grid>
    );
}

export default Index;