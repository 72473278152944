
import { Grid, Tab, Tabs} from '@mui/material';
import UserState from '../Atoms/UserAtom';
import { useRecoilValue } from 'recoil';
import {TabPanel,a11yProps } from '../Components/TabPanel';
import { useEffect, useState } from 'react';
import MassAdds from './Administration/MassAdds';
import AddBeer from './Administration/AddBeer';
import AddManufacturer from './Administration/AddManufacturer';
import AddType from './Administration/AddType';
import { GetIdentityDescriptor } from '../Api/AuthenticationApi';
import UserAdministration from './Administration/UserAdministration';
// const useStyles = makeStyles({
//   paper: {
//       padding: '15px',
//       margin: '10px'
//     //   marginBottom: '10px'
//   },
//   msgDiv:{
//     padding: '10px',
//     margin: 'auto',
//     alignContent: 'center'
//   }
// });


function AdminPage() {

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);
    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
          console.log(result);
          
          if(!result?.isAdministrator){
            window.location='/'
          }

          setIdentity(result)
        })
    }, [token]);
    
    console.log(identity);
    const [value, setValue] = useState(0);
    return (
  
        <Grid className="">

            <Grid item xs={12} container>
                <Grid item xs={12} >
                    <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
                        {/* <Tab label="Your stats" style={{backgroundColor:value===0?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(0)} /> */}
                        <Tab label="Mass inputs" style={{backgroundColor:value===0?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(1)} />
                        <Tab label="Input A Single Beer" style={{backgroundColor:value===1?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(2)} />
                        <Tab label="Input A Single Brewery" style={{backgroundColor:value===2?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(3)} />
                        <Tab label="Input A Single Style" style={{backgroundColor:value===3?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(4)} />
                        <Tab label="Users" style={{backgroundColor:value===4?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(5)} />
                        {/* <Tab label="Input A Single Country" style={{backgroundColor:value===1?'#AF000070':'transparent', borderRadius:"20px 20px 00px 0px"}} {...a11yProps(5)} /> */}
                        {/* <Tab label="Statistics"  {...a11yProps(2)} /> */}
                    </Tabs>
                </Grid>

                <TabPanel value={value} index={0}>
                    <MassAdds token={token} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <AddBeer token={token}/>
                </TabPanel>
                
                <TabPanel value={value} index={2}>
                    <AddManufacturer token={token}/>
                </TabPanel>
                
                <TabPanel value={value} index={3}>
                    <AddType token={token}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <UserAdministration token={token} />
                </TabPanel>
                {/* <TabPanel value={value} index={4}>
                    <AddCountry />
                </TabPanel> */}
                
            </Grid>
        </Grid>
    );
}

export default AdminPage;
