import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#787E9E',  // A lightened shade of Amber
            main: '#474D6B',  // Amber Ale
            dark: '#C56621',  // A darkened shade of Amber
            contrastText: '#F6F8FF',  // Brew Black,
        },
        secondary: {
            light: '#599096',  // A lightened shade of Stout Brown
            main: '#9BA1C0',  // Stout Brown
            dark: '#4F321A',  // A darkened shade of Stout Brown
            contrastText: '#1F2540',  // Barley Off-White
        },
        // tertiary: {
        //     light: '#26C3EB',  // A lightened shade of Pale Lager Yellow
        //     main: '#2647EB',  // Pale Lager Yellow
        //     dark: '#C5AA46',  // A darkened shade of Pale Lager Yellow
        //     contrastText: '#262626',  // Brew Black
        // },
        // quaternary: {
        //     light: '#A7C549',  // A lightened shade of Hop Green
        //     main: '#B8A469',  // Hop Green
        //     dark: '#688020',  // A darkened shade of Hop Green
        //     contrastText: '#F5E9D8',  // Barley Off-White
        // },
        // quinary: {
        //     light: '#F9F1D2',  // A lightened shade of Foamy White
        //     main: '#332E20',  // Foamy White
        //     dark: '#CFC2A3',  // A darkened shade of Foamy White
        //     contrastText: '#262626',  // Brew Black
        // },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#ffffff',
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: '#000000',
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#ffffff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#ffffff',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
        text: {
            primary: '#212121',
            secondary: '#757575',
            disabled: '#bdbdbd',
            hint: '#9e9e9e',
        }
    },
    shape: {
      borderRadius: '15px'
    },
    typography: {
        h1: {
          fontFamily: '"Tilt Neon", sans-serif !important',
          fontSize: '1.75rem !important', // Adjust as needed
          fontWeight: 600,
          marginBottom: '20px'
        },
        h2: {
          fontFamily: '"Tilt Neon", "Arial", sans-serif',
          fontWeight: 500,
          marginTop: '20px',
          marginBottom: '20px'
        },
        h3: {
          fontFamily: '"Tilt Neon", "Arial", sans-serif',
        },
        h4: {
          fontFamily: '"Tilt Neon", "Arial", sans-serif',
        },
        h5: {
          fontFamily: '"Tilt Neon", "Arial", sans-serif',
        },
        h6: {
          fontFamily: '"Tilt Neon", "Arial", sans-serif',
        },
        // ... and any other overrides you need
      },
    // Add other theme properties if needed
});

export default theme;
