import {  Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";


import {GetBeer} from '../../Api/BeerApi';

import ReviewForm from "../../Components/ReviewForm";
import { GetIdentityDescriptor } from "../../Api/AuthenticationApi";
// import { , QrCode } from "@mui/icons-material";



const ReviewBeerForEvent = ()=>{
    const {eventStringIdentifier, beerId} = useParams();
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);
    
    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
            console.log(result);

            setIdentity(result)
        })
    }, [token]);;

    const [beer, setBeer] = useState(null);
    
    useEffect(() => {
        GetBeer(eventStringIdentifier).then(result=>{
            console.log(result);
            setBeer(result);
        }).catch(error=>{

        });
    }, [eventStringIdentifier, setBeer])

   
    // console.log(id, members, feed, user)

    return (
        <Grid container spacing={2} justifyContent="center" >
            <Typography>You are reviewing a beer for an event</Typography>
            {beer && identity && <ReviewForm beerId={beerId} token={token} beerName={beer.name} eventStringIdentifier={eventStringIdentifier} reroute={`/events/${eventStringIdentifier}`}/>} 
        </Grid>
    )
}

export default ReviewBeerForEvent;