import { Button, Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { RespondToInvitation } from '../Api/GroupApi';
import UserState from '../Atoms/UserAtom';
import { useEffect, useState } from 'react';
import { GetIdentityDescriptor } from '../Api/AuthenticationApi';



const Notification = (props)=>{
    const {notification} = props;
    console.log(props);
    
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);
    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
          console.log(result);
          
          if(!result?.isAdministrator){
            window.location='/'
          }

          setIdentity(result)
        })
    }, [token]);

    console.log(identity);

    const handleAccept = ()=>{
        RespondToInvitation(token, notification.groupId, true);
        window.location.reload();
    }
    const handleReject = ()=>{
        RespondToInvitation(token, notification.groupId, false);
        window.location.reload();
    }

    return (<Grid item xs={12} container>
        {/* <Grid item xs={12}>
            
        </Grid> */}
        <Grid item xs={6}>
            {notification.inviterName} invited you to the group {notification.groupName}.
        </Grid>
        <Grid item xs={6} container>
            <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={handleAccept}>Accept</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" color="error" onClick={handleReject}>Deny</Button>
            </Grid>
        </Grid>
    </Grid>)
}

export default Notification;
