import React from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import { makeStyles } from '@mui/styles';
// seeded randomness
// const seededRandom = getSeededRandom(0.1);
// const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(4, 3);
// const data = genStats(5, randomNormal, () => 10 * seededRandom());
const useStyles = makeStyles({
    paper: {
        padding: '15px'
    },
    toolTipBase: {
        backgroundColor: 'white', 
        color:'inherit',
        fontSize: 'inherit',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', 
        padding: '5px 9px'
    },
    div: {
        margin: 'auto',
        height: "500px"
    }
});


// accessors
const ToolTip = item =>{
    console.log(item);
    const classes= useStyles();
    return<div className={classes.toolTipBase}>
        <span>{item.data.userName} - {item.data.score}</span>
    </div>
}

const MyResponsiveSwarmPlot = ({ data, groupBy}) => {

    const classes= useStyles();
    return (<div className={classes.div}><ResponsiveSwarmPlot
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ],
                [
                    'opacity',
                    0.5
                ]
            ]
        }}
        valueScale={{ type: 'linear', min: 0, max: 10, reverse: false }}
        // style={{height: '100px'}}
        forceStrength={4}
        simulationIterations={100}
        margin={{ top: 40, right: 30, bottom: 200, left: 50 }}
        tooltip={ToolTip}
        axisTop={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 85,
            legend: 'Beers',
            legendPosition: 'middle',
            legendOffset: 150
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'price if vertical, group if horizontal',
            legendPosition: 'middle',
            legendOffset: -76
        }}

    data={data} groupBy={groupBy} groups={Array.from(new Set(data.map(x=>x[groupBy])))} value="score"/>
    </div>
)}
export default MyResponsiveSwarmPlot;