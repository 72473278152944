import { Avatar, Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { GetBreweryList } from '../../Api/BeerApi';
import { useRecoilValue } from 'recoil';
import UserState from '../../Atoms/UserAtom';
import { AddManufacturerApiCall, UpdateBrewery } from '../../Api/AdminApi';
import ImageInput from '../../Components/ImageInput';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const EditBreweryModal = ({ open, onClose, brewery, onSave, countries }) => {
    const [editedBrewery, setEditedBrewery] = useState(brewery);

    useEffect(() => {
        // This ensures that when the brewery prop changes, the local state updates accordingly
        setEditedBrewery(brewery);
    }, [brewery]);
    const matchingCountry = countries.filter(c => c.value.Name === editedBrewery?.country?.Name)[0];
    console.log(matchingCountry, editedBrewery);
    const handleSave = () => {
        onSave(editedBrewery);
        onClose();
    };
    console.log(editedBrewery);
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box component="div" p={3} style={{ backgroundColor: 'white', margin: 'auto', width: '50%' }}>
                    <Typography variant="h5" gutterBottom>
                        Edit Manufacturer
                    </Typography>
                    <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        value={editedBrewery?.name || ''}
                        onChange={(e) => setEditedBrewery(prev => ({ ...prev, name: e.target.value }))}
                    />
                    
                    <Box mb={2}>
                        <ImageInput onImageChange={(base64Image) => setEditedBrewery(prev => ({ ...prev, b64Logo: base64Image }))} />

                        {/* Preview for the logo */}
                        {editedBrewery?.b64Logo && (
                            <Box mt={2}>
                                <Typography variant="body2" gutterBottom>
                                    Logo Preview:
                                </Typography>
                                <img 
                                    src={editedBrewery.b64Logo} 
                                    alt="Brewery Logo Preview" 
                                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', boxShadow: '0 2px 8px rgba(0,0,0,0.2)' }}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box mb={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Country</InputLabel>
                            <Select
                                value={matchingCountry?.value || {}}
                                onChange={(e) => setEditedBrewery(prev => ({ ...prev, country: e.target.value }))}
                                label="Country"
                            >
                                {countries.map((country, index) => (
                                    <MenuItem key={index} value={country.value}>
                                        {country.value.Name} {/* This should display the country name */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>


                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
};

const AddManufacturer = () => {
    const [name, setName] = useState('');
    const [logoUrl, setLogoUrl] = useState(''); // Changed from description
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null); 
    const [breweries, setBreweries] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingBrewery, setEditingBrewery] = useState(null);
    const token = useRecoilValue(UserState);
    // const theme = useTheme();
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(`Submitting ${name} (${logoUrl})`);
        AddManufacturerApiCall(token, { Name: name, B64Logo: logoUrl, CountryDto: selectedCountry })
    };
    

    useEffect(() => {
        // Fetch list of countries from API

        GetBreweryList().then(data => { 
            console.log(data);
            setBreweries(data);
        });


        fetch('https://restcountries.com/v3.1/all')
        .then(response => response.json())
        .then(data => {
            // Map country data to an array of objects with label and value properties
            data = data.filter(x=>typeof(x['subregion']) !== typeof(undefined) )
            data = data.filter(x=>typeof(x['capital']) !== typeof(undefined) )
            const countryOptions = data.map(country => ({
                label: country.name.common,
                value: { 
                    Name: country.name.common,
                    AltSpelling: country.altSpellings[0],
                    SubRegion: country.subregion, 
                    Region: country.region, 
                    FlagUrl: country.flags.svg, 
                    Indipendent: country.independent, 
                    LandLocked: country.landlocked, 
                    Area: country.area, Population: country.population, 
                    Capital: country.capital[0], 
                    Currency: country.currencies[Object.keys(country.currencies)[0] ].name, 
                    Language: country.languages[Object.keys(country.languages)[0] ]
                }
            }));
            countryOptions.sort((a, b) => {
                let fa = a.label.toLowerCase(),
                    fb = b.label.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            setCountries(countryOptions);
            setSelectedCountry(countryOptions[0].value);
        })
        .catch(error => console.log(error));
    }, []);

    return ( <>
            <EditBreweryModal 
                open={isEditModalOpen} 
                onClose={() => setIsEditModalOpen(false)} 
                brewery={editingBrewery} 
                countries={countries}
                style={{ zIndex:  99999991 }}  // This ensures the modal's z-index is higher than the backdrop

                BackdropComponent={Backdrop}
                onSave={(updatedBrewery) => {
                    // Save the updated brewery details, probably using another API call
                    UpdateBrewery(token, updatedBrewery).then(()=>{
                        GetBreweryList().then(data => { 
                            console.log(data);
                            setBreweries(data);
                        });
                
                    })
                    setIsEditModalOpen(false);
                }} 
                BackdropProps={{
                    timeout: 500,
                    style: { opacity: 0.8 }  // Adjusts the opacity of the backdrop to make it lighter
                }}
                disableBackdropClick  // Prevents the modal from closing when the backdrop is clicked
            />

            <Box component="div" p={3}>
                <Paper elevation={3} style={{ padding: '24px' }}>
                    <Typography variant="h4" gutterBottom>
                        Add Manufacturer
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Box>

                        
                        <Box mb={2}>
                            <ImageInput onImageChange={(base64Image) => setLogoUrl(base64Image)} />
                        </Box>
                        
                        {countries.length === 0 ? <>
                            Loading
                        </> : <>
                            <Box mb={2}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        value={selectedCountry}
                                        onChange={(e) => setSelectedCountry(e.target.value)}
                                        label="Country"
                                    >
                                        {countries.map((country, index) => (
                                            <MenuItem key={index} value={country.value}>
                                                {country.value.Name} {/* This should display the country name */}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>}

                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Paper>
            </Box>

            <Box component="div" p={3}>
                {/* ... other components ... */}
                <Typography variant="h5" gutterBottom>
                    List of Breweries
                </Typography>
                <Grid container spacing={3}>
                    {breweries.map(brewery => (
                        <Grid item xs={12} sm={6} md={4} key={brewery.id}>
                            <Card elevation={3} style={{ position: 'relative' }}>
                                <div style={{ 
                                    position: 'absolute', 
                                    top: 10, 
                                    right: 10,
                                    display: 'flex',
                                    gap: '8px'
                                }}>
                                    <Avatar 
                                        src={brewery.b64Logo} 
                                        alt={brewery.name} 
                                        style={{ width: 40, height: 40 }}
                                    />
                                    <Avatar 
                                        src={brewery.country.flagUrl} 
                                        alt={brewery.country.name} 
                                        style={{ width: 40, height: 40 }}
                                    />
                                </div>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {brewery.name}
                                    </Typography>
                                    <Button 
                                        size="small" 
                                        onClick={() => {
                                            setEditingBrewery(brewery);
                                            setIsEditModalOpen(true);
                                        }}>
                                        Edit
                                    </Button>
                                    <Typography variant="body2" color="textSecondary">
                                        {brewery.country.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            
        </>
    );
};

export default AddManufacturer;
