import PropTypes from 'prop-types';
import { Grid, Tab } from '@mui/material';

export function TabPanel({ children, value, index, ...other }) {
    if (value !== index) {
        return null; // Don't render the tab content if it's not active
    }
    return (
        <Grid 
            item xs={12}
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {children}
        </Grid>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
};

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function StyledTab({ label, index }) {
    return (
        <Tab label={label} {...a11yProps(index)} />
    );
}

StyledTab.propTypes = {
    label: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired
};