import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import UserState from '../../Atoms/UserAtom';
import { GetReccomendations } from '../../Api/BeerApi';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import RecommendationCard from './ReccomendationCard';


        

const Recommendations = () => {
    const token = useRecoilValue(UserState);
    const [recommendations, setRecommendations] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);



    useEffect(() => {
    GetReccomendations(token)
        .then(result => {
            setRecommendations(result);
            setIsLoading(false);
        })
        .catch(error => {
            console.error("Failed to fetch recommendations:", error);
            setError(error.toString());
            setIsLoading(false);
        });
    }, [token]);


    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!recommendations) {
        return <Typography>No recommendations available at the moment.</Typography>;
    }

  const { beerOfTheDay, globallyUnexplored, favoriteTypeReccomended, recommendedUnexplored} = recommendations;

  return (
        <div>
            <Typography variant="h2" align='center' gutterBottom>Reccomendations</Typography>
            <Grid container justifyContent='center' spacing={2}>
                {/* Beer of the Day */}
                {beerOfTheDay && (
                    <RecommendationCard
                        title="Beer of the Day"
                        beer={beerOfTheDay}
                        description={`Handpicked by us, enjoy the distinct flavors of `}
                        alt={`Logo of ${beerOfTheDay.name}`}
                        link={`beer/${beerOfTheDay.id}`}
                    />
                )}

                {/* Globally Unexplored */}
                {globallyUnexplored && (
                    <RecommendationCard
                        title="A less explored road"
                        beer={globallyUnexplored}
                        description={'Take the road less traveled with'}
                        postscript={`, a unique brew that's waiting for its first explorers.`}
                        alt={`Logo of ${globallyUnexplored.name}`}
                        link={`beer/${globallyUnexplored.id}`}
                    />
                )}

                {favoriteTypeReccomended && (
                    <RecommendationCard
                        title={`Because you like ${favoriteTypeReccomended.typeName}`}
                        beer={favoriteTypeReccomended}
                        description={'We think you might like '}
                        postscript={`, the current highest rated beer for that type!`}
                        alt={`Logo of ${favoriteTypeReccomended.name}`}
                        link={`beer/${favoriteTypeReccomended.id}`}
                    />
                )}

                {recommendedUnexplored && (
                    <RecommendationCard
                        title={`Surprise your taste buds`}
                        beer={recommendedUnexplored}
                        description={`You haven't tried a lot of ${recommendedUnexplored.typeName}, so we think you might like`}
                        postscript={`, the current highest rated beer for that type!`}
                        alt={`Logo of ${recommendedUnexplored.name}`}
                        link={`beer/${recommendedUnexplored.id}`}
                    />
                )}
            </Grid>
        </div>
    );
};

export default Recommendations;