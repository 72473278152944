import { Typography, Grid, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Zoom from '@mui/material/Zoom';

const useStyles = makeStyles(theme => ({ 
    card: {
        marginTop: '20px',
        color: '#AEEFF0',
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
        borderRadius: '10px',
        boxShadow: '0 3px 5px 2px rgba(25, 133, 161, 0.3)',
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'scale(1.03)',
        },
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(33% - 20px)',
            margin: '10px'
        }
    },
    number: {
        color: '#AEEFF0',
        textShadow: '0 0 5px #66E0DD',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    text: {
        color: '#E8F1F2',
        opacity: 0.9,
        fontSize: '0.9rem',
        textAlign: 'center'
    }
}));

function IndexStats({beers}) {
    const classes = useStyles();
    console.log(beers);
    const breweryCount = new Set(beers.map(b => b.breweryId)).size;
    const typeCount = new Set(beers.map(b => b.typeId)).size;

    return (
        <Zoom in={true}>
            <Grid container justifyContent="center" alignItems="center" flexDirection="row" >
                <Typography variant="h3" className={classes.text}>This cristmas, we have</Typography>
                <Grid container justifyContent="center" alignItems="center">

                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="subtitle1" className={classes.text}>We have</Typography>
                            <Typography variant="h2" className={classes.number}>{beers.length}</Typography>
                            <Typography variant="subtitle1" className={classes.text}>beers</Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.card}>
                        <CardContent>
                            
                        <Typography variant="subtitle1" className={classes.text}>Of</Typography>
                            <Typography variant="h2" className={classes.number}>{typeCount}</Typography>
                            <Typography variant="subtitle1" className={classes.text}>types</Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="subtitle1" textAlign="center" className={classes.text}>From</Typography>
                            <Typography variant="h2" className={classes.number}>{breweryCount}</Typography>
                            <Typography variant="subtitle1" textAlign="center" className={classes.text}>breweries</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Zoom>
    );
}

export default IndexStats;
