
import { Grid } from '@mui/material';
import ReviewListItem from '../Components/ReviewListItem';
import { makeStyles } from '@mui/styles';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { GetIdentityDescriptor } from '../Api/AuthenticationApi';
import UserState from '../Atoms/UserAtom';
const useStyles = makeStyles({
    RLroot: {
        marginBottom: '25px'
    }
});

function Register(props) {
    const {reviews, displayBeerName} = props;
    const classes = useStyles();
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);
    useEffect(() => {
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
          setIdentity(result)
        })
    }, [token]);

    reviews.sort((a, b)=> new Date(b.timestamp)-new Date(a.timestamp))
    return (
        <Grid container className={classes.RLroot} justifyContent="center" spacing={2}>
            {reviews.map(review=><ReviewListItem identity={identity} key={`review:${review.id}`} review={review} displayBeerName={displayBeerName===true} />)}
        </Grid>
    );
}

export default Register;
