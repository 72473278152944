
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

import { Paper } from '@mui/material';
import { GetTypeList } from '../../Api/BeerApi';
import { AddTypeApiCall } from '../../Api/AdminApi';

function AddType({token}) {
    const [type, setType] = useState('');
    const [typeList, setTypeList] = useState([]);
    useEffect( () => {
        // Make API call to get list of types from backend
        GetTypeList().then(data => {
            console.log(data);
            setTypeList(data)
        } );        
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await AddTypeApiCall(token, { Name: type });
        setTypeList(await GetTypeList());
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>Add Type</Typography>
            <Paper sx={{ p: 2 }}>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Box mb={2}>
                        <TextField
                            label="Type"
                            variant="outlined"
                            fullWidth
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </Box>
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </Paper>

            <Paper>
                {/* type is like this {id: 3, name: 'asdas'} */}
                <ul>
                    {typeList.map((type) => (
                        <li key={type.id}>{type.name}</li>
                    ))}
                </ul>
            </Paper>
        </Container>
    );
}

export default AddType;
