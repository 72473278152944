import React from 'react';
import {
    Grid, Typography //, Paper, List, ListItem, ListItemText, Divider
} from '@mui/material';
// import Avatar from '@mui/material/Avatar';

function UserProfileHomeTab() {
    // Placeholder data for the user's personal details
    // const user = {
    //     name: "John Doe",
    //     username: "BeerLover23",
    //     profilePic: "path_to_image.jpg",
    //     joinDate: "2020-01-01",
    //     favoriteBrewery: "BrewCrafters Ltd.",
    //     totalReviews: 50,
    //     ratingBreakdown: {
    //         5: 10,
    //         4: 20,
    //         3: 15,
    //         2: 3,
    //         1: 2
    //     },
    //     tasteProfile: {
    //         preferredTypes: ['IPA', 'Stout', 'Lager'],
    //         favoriteHop: 'Cascade',
    //         bitternessLevel: 'Medium-High',
    //         alcoholContent: 'Medium',
    //         flavors: ['Citrus', 'Pine', 'Malty']
    //     }
    // };

    return (
        <Grid container spacing={3} alignContent="center">
            {/* Personal Details & Avatar */}

            {/* <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                    <Avatar src={user.profilePic} alt="Profile" style={{ width: '100px', height: '100px', margin: '0 auto' }} />
                    <Typography variant="h6" style={{ marginTop: '16px' }}>{user.name}</Typography>
                    <Typography color="textSecondary">{user.username}</Typography>
                    <Typography color="textSecondary">Joined: {user.joinDate}</Typography>
                </Paper>
            </Grid> */}

            {/* My Beer Reviews & Stats */}
            {/* <Grid item xs={12} sm={8}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                    <Typography variant="h6">My Beer Stats</Typography>
                    <Typography>Total Reviews: {user.totalReviews}</Typography>
                    <Typography>Favorite Brewery: {user.favoriteBrewery}</Typography>
                    <List>
                        {Object.entries(user.ratingBreakdown).map(([rating, count]) => (
                            <ListItem key={rating}>
                                <ListItemText primary={`${rating} Stars`} secondary={`${count} Reviews`} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid> */}


            <Grid item xs={8}>

                <Typography align='center' variant='h3'>We are actively working on the user profile, hang on tight and keep enjoying the festive brews ... </Typography>
            </Grid>





            {/* <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                    <Typography variant="h6">Taste Profile</Typography>
                    <Divider style={{ margin: '16px 0' }} />

                    <List>
                        <ListItem>
                            <ListItemText primary="Preferred Beer Types" secondary={user.tasteProfile.preferredTypes.join(', ')} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Favorite Hop" secondary={user.tasteProfile.favoriteHop} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Bitterness Level" secondary={user.tasteProfile.bitternessLevel} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Preferred Alcohol Content" secondary={user.tasteProfile.alcoholContent} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Frequent Flavors" secondary={user.tasteProfile.flavors.join(', ')} />
                        </ListItem>
                    </List>
                </Paper>
            </Grid> */}


        </Grid>
    );
}

export default UserProfileHomeTab;
