import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, TextField, Typography, Switch, CircularProgress, Link, Tooltip, IconButton } from '@mui/material';
import UserState from '../../Atoms/UserAtom';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
// import { CreateGroup, GetMyEvents } from '../../Api/AuthenticationApi';
import { makeStyles } from '@mui/styles';
import { CreateEvent, DeleteEvent, GetEvents } from '../../Api/EventApi';
import {useTranslation} from 'react-i18next';
import { GetIdentityDescriptor } from '../../Api/AuthenticationApi';
import DeleteIcon from '@mui/icons-material/Delete';


const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    accordion: {
      margin: theme.spacing(1, 0),
    }
  }));


function MyEvents() {
    const {t} = useTranslation();
    const classes = useStyles();
    const [events, setEvents] = useState(null);


    const [newEventPublic, setNewEventPublic] = useState(true);
    const [newEventTitle, setNewEventTitle] = useState("");

    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);

    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
            console.log(result);
          
            setIdentity(result)
        })
    }, [token]);



    useEffect(() => {
        console.log(identity);
        if(identity == null){ return; }
        GetEvents(token).then(result=>{
            setEvents(result);
        }).catch(error=>{

        });
        
    }, [setEvents, identity, token])

    function AddEvent(){
        if(identity === null){ return; }
        CreateEvent(token, newEventTitle, newEventPublic).then(x=>{
            window.location.reload();
        })
    }

    const EventListItem = ({event}) => (
        <Grid container item xs={12} key={`${event.stringIdentifier}:${event.title}`} alignItems="center">
            <Grid item xs={10}>
                <Link className={classes.link} href={`/events/${event.stringIdentifier}`}>{event.title}</Link> 
            </Grid>
            <Grid item xs={2}>
                <Tooltip title={t('events.delete')}>
                    <IconButton onClick={()=>{
                        DeleteEvent(token, event.stringIdentifier).then(()=>{
                            window.location.reload();
                        })
                    }}>
                        <DeleteIcon color="error" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
  
  return (
    <Grid container spacing={2} alignContent="center">
        {/* Create New Event */}
        <Grid item xs={12}>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<AddIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>{t('events.createNew')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Title" value={newEventTitle} onChange={(e) => setNewEventTitle(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Private</Grid>
                                    <Grid item>
                                        <Switch checked={newEventPublic} onChange={() => setNewEventPublic(!newEventPublic)} />
                                    </Grid>
                                    <Grid item>Public</Grid>
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button fullWidth variant="contained" color="primary" onClick={AddEvent}>Add</Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
        {/* List of Events */}
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                {events === null && <CircularProgress />}
                {events !== null && <Grid container spacing={2}>
                    {events.map(event => <EventListItem event={event} />)}
                </Grid>}
            </Paper>
        </Grid>
    </Grid>
    );
}

export default MyEvents;
