import {  Grid} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";
import {GetReviewsForEvent} from '../../Api/EventApi'
import {useTranslation} from 'react-i18next';
import {Typography } from '@mui/material';
import ReviewListItem from "../../Components/ReviewListItem";



const EventReviewList = ({eventId})=>{
    const {t} = useTranslation()
    const [reviews, setReviews] = useState([]);
    const token = useRecoilValue(UserState);
    
    useEffect(() => {
        if(token == null){ return; }
        GetReviewsForEvent(token, eventId).then(data =>{
            setReviews(data);
        }).catch(error=>{
    
        });
    }, [eventId, setReviews, token])

    return (
        <Grid container spacing={2} justifyContent="center" alignContent="center" >
            <Grid item xs={8}>
                <Typography>{t('events.reviewsForThisEvent')}</Typography>
            </Grid>
            {reviews &&
                <Grid item xs={12}>
                    {reviews.map(review=><ReviewListItem key={`id: ${review.id}`} review={review} displayBeerName />)}           
                </Grid>
            } 
        </Grid>
    )
}

export default EventReviewList;