
export function GetScoreVsABV(data){
    console.log(data);
    return new Promise((resolve, reject)=>{
        let d = [];
        if(data.length === null){ resolve([]); }
        const beerNames = new Set(data.map(x=>x.beerName))
        
        beerNames.forEach(element => {
            const rs = data.filter(x=>x.beerName===element);
            const abv = rs[0].abv;
            const average =  rs.map(x=>x.score).reduce((a, b) => a + b) / rs.length;
            d.push({y:average,x:abv,label:element})
        })

        // data.forEach(element => {
        //     d.push({y:element.score,x:element.abv*100,label:element.beerName})
        // });
        console.log(d);
        d.sort((a,b)=> b-a );
        resolve(d)
    })
}

export function GetScoreVsABVGroupByTypes(data){
    return new Promise((resolve, reject)=>{
        let d = [];
        if(data.length === 0){ resolve([]); }
        const typeNames = new Set(data.map(x=>x.beerTypeName))
        
        typeNames.forEach(type => {
            
            const r = {id:type, data:[]}
            var beersOfType = data.filter(x=>x.beerTypeName === type)
            const beerNames = new Set(beersOfType.map(x=>x.beerName))
        
            beerNames.forEach(element => {
                const rs = data.filter(x=>x.beerName===element);
                const abv = rs[0].abv;
                const average =  rs.map(x=>x.score).reduce((a, b) => a + b) / rs.length;
                r.data.push({y:average,x:abv,label:element})
            })
            d.push(r);
        })

        // data.forEach(element => {
        //     d.push({y:element.score,x:element.abv*100,label:element.beerName})
        // });
        // console.log(d);
        // d.sort((a,b)=> b-a );
        resolve(d)
    })
}







export function GetScoreDistributionForBeers(data){
    return new Promise((resolve, reject)=>{
        let d = [];
        if(data.length === 0){resolve([])}
        data.forEach(element =>{


            d.push({group: element.beerName, id:element.id, score: element.score})

        })
        // const beerNames = new Set(data.map(x=>x.beerName))
        
        // beerNames.forEach(element => {
        //     const rs = data.filter(x=>x.beerName===element);
        //     const abv = rs[0].abv;
        //     const average =  rs.map(x=>x.score).reduce((a, b) => a + b) / rs.length;
        //     d.push({y:average,x:abv,label:element})
        // })

        // data.forEach(element => {
        //     d.push({y:element.score,x:element.abv*100,label:element.beerName})
        // });
        resolve(d)
    })
}

export function DropNull(data, column){
    return new Promise((res, rej)=>{
        if(data.length === 0){ res([]); }
        const statsWithServingTypes = data.filter(x=>x[column] !== null);
        res(statsWithServingTypes)
    })
}

export function GetScoreByServingTypesSwarm(data){
    return new Promise((res, rej)=>{
        if(data.length === 0){ res([]); }
        let d = [];
        const statsWithServingTypes = data.filter(x=>x.servingTypeString !== null);
        if(statsWithServingTypes.length === 0){ res([]);}


        statsWithServingTypes.forEach(element =>{


            d.push({group: element.servingTypeString, id:element.id, score: element.score})

        })
        res(d)
        // const statsWithServingTypes = data.filter(x=>x.servingTypeString !== null);
        // if(statsWithServingTypes.length === 0){ res([]);}
        // const servingTypeNames = new Set(data.filter(x=>x!==null).map(x=>x.servingTypeString))
        // // const servingTypeNames = new Set(data.map(x=>x.servingTypeString))
        // // if(data)
        // servingTypeNames.forEach(type => {
            
        //     const r = {id:type, data:[]}
        //     var statsWithServeType = statsWithServingTypes.filter(x=>x.beerTypeName === type)
        //     const beerNames = new Set(statsWithServeType.map(x=>x.beerName))
        
        //     beerNames.forEach(element => {
        //         const rs = data.filter(x=>x.beerName===element);
        //         const abv = rs[0].abv;
        //         const average =  rs.map(x=>x.score).reduce((a, b) => a + b) / rs.length;
        //         r.data.push({y:average,x:abv,label:element})
        //     })
        //     d.push(r);
        // })
        // res(d)
    })
}