import React from 'react';
import { Box, Grid, Slider, Typography, Card, CardMedia,CardContent } from '@mui/material';
import BeerIcon from '@mui/icons-material/LocalDrink'; // Example beer icon
import { useTranslation } from 'react-i18next';
import { ArrowDownward, ArrowUpward} from '@mui/icons-material';

function ReviewScoreSlider(props) {
    const { t } = useTranslation();
    const { scorings, setScore, score, beerName } = props;

    let ratingsLessThanCurrentScore = scorings.filter(x=>x.averageScore <= score).sort((x, y)=>y.averageScore-x.averageScore);
    let ratingsMoreThanCurrentScore = scorings.filter(x=>x.averageScore >= score).sort((x, y)=>x.averageScore-y.averageScore);
    let allowed_extra_less_thans = Math.max(0, 3-ratingsMoreThanCurrentScore.length);
    let allowed_extra_more_thans = Math.max(0, 3-ratingsLessThanCurrentScore.length);
    ratingsLessThanCurrentScore = ratingsLessThanCurrentScore.slice(0, 3+allowed_extra_less_thans).sort((x, y)=>y.averageScore-x.averageScore);
    ratingsMoreThanCurrentScore = ratingsMoreThanCurrentScore.slice(0, 3+allowed_extra_more_thans).sort((x, y)=>y.averageScore-x.averageScore);

    const getColor = () => score < 3.3 ? 'red' : score < 6.6 ? 'orange' : 'green';

    const BreweryLogo = ({ b64Logo }) => (
        <CardMedia 
            component="img"
            sx={{
                width: 40, 
                height: 40,
                borderRadius: '50%',
                objectFit: 'contain'
            }}
            image={b64Logo}
            alt="Brewery logo"
            onError={(e) => {
                e.target.onerror = null;
                e.target.src="path/to/default/logo.png";
            }}
        />
    );
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6" align="center">{t('reviewForm.yourScore')}:</Typography>
            </Grid>
            <Grid item xs={10}>
                <Slider 
                    value={score} 
                    onChange={(ev, newValue) => setScore(newValue)}
                    min={0} 
                    max={10} 
                    step={0.1} 
                    aria-label="Rating" 
                    valueLabelDisplay="auto"
                    sx={{
                        color: getColor(),
                        '& .MuiSlider-thumb': {
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0 0 12px ${getColor()}`, // dynamic focus shadow based on the color
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <Typography align="center" variant="h6">{score}/10</Typography>
            </Grid>
            
            <Grid item xs={12}>
    <Typography variant="subtitle1" align="center" style={{ marginBottom: '20px' }}>
        Rate Your Beer
    </Typography>

    <Box display="flex" flexDirection="column" alignItems="center">
        {ratingsMoreThanCurrentScore.map(r => (
            <Card 
                sx={{ 
                    width: '80%', 
                    marginBottom: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    backgroundColor: 'rgba(230, 230, 255, 1)', 
                    flexDirection: 'row-reverse'
                }} 
                key={`${r.beerName}${r.averageScore}`}
            >
                <ArrowUpward color="primary" style={{ marginRight: '8px' }} />
                <BreweryLogo b64Logo={r.breweryB64Logo} />
                <CardContent>
                    <Typography variant="body2">{r.beerName} ({r.averageScore})</Typography>
                </CardContent>
            </Card>
        ))}

        <Box 
            sx={{ 
                width: '85%', 
                marginBottom: 3, 
                marginTop: 3,
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: 'rgba(245, 245, 245, 1)', 
                padding: 2,
                borderRadius: 2,
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
                border: '1px solid rgba(0, 0, 0, 0.1)'
            }}
        >
            <BeerIcon style={{ fontSize: 40, marginRight: '12px', color: 'rgba(0, 0, 0, 0.7)' }} />
            <Typography variant="h6">{beerName} ({score})</Typography>
        </Box>

        {ratingsLessThanCurrentScore.map(r => (
            <Card 
                sx={{ 
                    width: '80%', 
                    marginBottom: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    backgroundColor: 'rgba(255, 230, 230, 1)'
                }} 
                key={`${r.beerName}${r.averageScore}`}
            >
                <ArrowDownward color="error" style={{ marginRight: '8px' }} />
                <BreweryLogo b64Logo={r.breweryB64Logo} />
                <CardContent>
                    <Typography variant="body2">{r.beerName} ({r.averageScore})</Typography>
                </CardContent>
            </Card>
        ))}
    </Box>
</Grid>

             
        </Grid>
    );
}

export default ReviewScoreSlider;