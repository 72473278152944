// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/scatterplot
import { makeStyles } from '@mui/styles';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'



const useStyles = makeStyles({
    paper: {
        padding: '15px'
    },
    toolTipBase: {
        backgroundColor: 'white', 
        color:'inherit',
        fontSize: 'inherit',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', 
        padding: '5px 9px'
    },
    div: {
        margin: 'auto',
        height: "500px"
    }
});





const CustomTick = (tick)=>{
    return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
            {/* <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
            <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" /> */}
            {/* <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} /> */}
            <text textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fill: '#333',
                    fontSize: 10
                }}
            >
                {(tick.value*100).toFixed(0)}%
            </text>
        </g>
        )
}
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const ToolTip = item =>{
    const classes= useStyles();
    return<div className={classes.toolTipBase}>
        <span>{item.node.data.label}</span>
    </div>
}


const MyResponsiveScatterPlot = ({ data /* see data tab */ }) => {
    const classes = useStyles();
    return (<div className={classes.div}><ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: 60, bottom: 80, left: 50 }}
        xScale={{ type: 'linear', min: 0, max: 0.2}}
        xFormat={v=>`${v*100}%`}
        yScale={{ type: 'linear', min: 0, max: 10.5 }}
        yFormat=">-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        tooltip={ToolTip}
        colors={{ scheme: 'paired' }}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            renderTick: CustomTick,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'ABV',
            legendPosition: 'middle',
            legendOffset: 46
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        legends={[
            {
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 10,
                translateY: -40,
                itemWidth: 100,
                itemHeight: 12,
                itemsSpacing: 5,
                itemDirection: 'left-to-right',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    /></div>
)}
export default MyResponsiveScatterPlot;