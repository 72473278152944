import { Card, CardContent, CardMedia, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GetBreweryProfilePic } from "../../Api/BeerApi";
const RecommendationCard = ({title, description, alt, beer, postscript}) => {

    const [profile, setProfile] = useState(null);
    useEffect(() => {
        GetBreweryProfilePic(beer.brewery.id).then(result => {
            setProfile(result);
        })
    }, [beer]);
    
    if(!beer) {
        return <CircularProgress />;
    }

    return (
        <Grid item xs={8}>
            <Card>
                <CardMedia
                    component="img"
                    height="140"
                    image={profile}
                    alt={alt}
                    style={{ objectFit: 'contain' }}
                    />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description} <Link to={`beer/${beer.id}`}>{beer.name}</Link> {postscript}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default RecommendationCard;