import { Grid, Link, Paper, Typography } from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.main, // Amber Ale
            padding: '10px',
            color: theme.palette.primary.contrastText, // Brew Black
        },
        svgIcon: {
            height: '50px',
            width: '50px'
        },
        BeerInfoRoot: {
            paddingBottom: '15px'
        },
        link: {
            color: theme.palette.secondary.main, // Golden Hops
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '@media (max-width:600px)': {
                fontSize: '1rem',
            }
        },
        breweryText: {
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            }
        },
        someClass: {
            // ... [rest of the styles remains unchanged]
            
            width: '50px',  // or adjust as per your requirement
            height: '50px',
            borderRadius: '50%',  // makes it circular
            backgroundSize: 'contain',  // scales the image to cover the entire div
            backgroundRepeat: 'no-repeat',  // ensures the image doesn't repeat
            backgroundPosition: 'center',  // centers the image
            marginBottom: theme.spacing(1),  // adds some space below the logo
            
        }
    };
});

function round(n) {
    return Math.round(n * 100) / 100;
}
function BeerInfo(props) {
    const {beer} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    
    return (
        <Grid item xs={12} sm={12} container justifyContent="center" spacing={2} className={classes.BeerInfoRoot}>
            <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} style={{height: '100%'}}>
                    <Typography align="center" variant="h2">
                        {beer.name}
                    </Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={6} container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography align="center" variant='h4' className={classes.breweryText}>
                            <div className={classes.logoContainer}>
                                <div className={classes.someClass} style={{backgroundImage: `url(${beer.brewery.b64Logo})`}}></div>
                                {t('beer.brewery')}:
                                <Link className={classes.link} href={`/brewery/${beer.brewery.id}`}> {beer.brewery.name} </Link>
                            </div>
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography align="center" variant='h5'>
                            {t('beer.type')}:
                            <Link className={classes.link} href={`/type/${beer.typeId}`}> {beer.typeName} </Link>
                        </Typography>
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography align="center" variant='h6'>
                            {t('beer.ABV')}: {round(beer.abv)}% 
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BeerInfo;