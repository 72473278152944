import React from 'react';
// // import ReactDOM from 'react-dom';
// var ReactDOM = require('react-dom/client');
import * as ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Navbar from './Components/Navbar'
import { RecoilRoot } from 'recoil';
import Login from './Pages/Authentication/Login';
import Index from './Pages/Index';
import BeerProfile from './Pages/BeerProfile';
import UserState from './Atoms/UserAtom';
import Profile from './Pages/Authentication/Profile';
import ShoppingList from './Pages/ShoppingList';
import Register from './Pages/Authentication/Register';
import BreweryList from './Pages/BreweryList';
import TypeList from './Pages/TypeList';
import BreweryProfile from './Pages/BreweryProfile';
import TypeProfile from './Pages/TypeProfile';
import MyGroups from './Pages/Groups/MyGroups';
import GroupProfile from './Pages/Groups/GroupProfile';
import MyEvents from './Pages/Events/MyEvents';
import EventProfile from './Pages/Events/EventProfile';
import './i18n'
import ReviewBeerForEvent from './Pages/Events/ReviewBeerForEvent';
import Verify from './Pages/Authentication/Verify';
import ResetPassword from './Pages/Authentication/ResetPassword';
import RequestPasswordReset from './Pages/Authentication/RequestPasswordReset';
import AdminPage from './Pages/Admin';
import { ThemeProvider } from '@mui/styles';
import theme from './theme';
import AboutPage from './Pages/About';

export function initState(snapshot) {
  const data = localStorage.getItem("user")
  if (!data){
    return
  }
  const token = data
  snapshot.set(UserState, token)
}
const reload = () => window.location.reload();
const root = ReactDOM.createRoot(document.getElementById('root'))
// <React.StrictMode>
root.render(
    <ThemeProvider theme={theme}>
        <RecoilRoot initializeState={initState}>
                <BrowserRouter>
                    <Navbar />
                    <div style={{width: '90%', margin:'auto'}}>
                        <Routes>
                            <Route path="/" element={<Index />} />
                            <Route path="shoppinglist" element={<ShoppingList />} />
                            <Route path="login" element={<Login />} />
                            <Route path="groups" element={<MyGroups />} />
                            <Route path="groups/:groupId" element={<GroupProfile />} />
                            <Route path="events" element={<MyEvents />} />
                            <Route path="events/:id" element={<EventProfile />} />
                            <Route path="register" element={<Register />} />
                            <Route path="beer/:id" element={<BeerProfile />} />
                            <Route path="breweries" element={<BreweryList />} />
                            <Route path="brewery/:id" element={<BreweryProfile />} />
                            <Route path="types" element={<TypeList />} />
                            <Route path="type/:id" element={<TypeProfile />} />
                            <Route path="account/verify/:token" element={<Verify />} />
                            <Route path="account/reset/:token" element={<ResetPassword />} />
                            <Route path="account/reset" element={<RequestPasswordReset />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="about" element={<AboutPage />} />
                            <Route path="admin" element={<AdminPage />} />
                            <Route path="event/:eventStringIdentifier/review/:beerId" element={<ReviewBeerForEvent />} />
                            <Route path=".well-known/acme-challenge/1cMvGHIs11EyZL7GS9KHSQR0rYu3TdV7H5z9l2ACL4A" onenter={reload} />
                            {/* <Route path="invoices" element={<Invoices />} /> */}
                        </Routes>
                    </div>
                </BrowserRouter>
        </RecoilRoot>
    </ThemeProvider>
);
// </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
