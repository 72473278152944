import { Grid, Paper} from "@mui/material";
import { useEffect, useState } from "react";
import {Typography } from '@mui/material';
import { DropNull} from "../../Helpers/StatsPromises";
// import MyResponsiveScatterPlot from "../../Components/Stats/MyResponsiveScatterPlot";
import { GetStatisticsForBeer } from "../../Api/BeerApi";
import { makeStyles } from "@mui/styles";
import MyResponsiveSwarmPlot from "../../Components/Stats/MyResponsiveSwarmPlot";

const useStyles = makeStyles({
    paper: {
      padding: '15px',
      marginBottom: '10px'
    }
  });



const useFetch = (setAbvVsServingTypeData, setScoreVsBeerNumber, beerId)=>{useEffect(() => {


    GetStatisticsForBeer(beerId).then(data => {
        
        DropNull(data, "servingTypeString").then(d=>{
            console.log(d);
            setAbvVsServingTypeData(d);
        })
        DropNull(data, "beerNumber").then(d=>{
        setScoreVsBeerNumber(d)
        })

    }).catch(error=>{
        console.log(error);
    })

}, [setAbvVsServingTypeData, setScoreVsBeerNumber, beerId])}




const BeerStatistics = ({beerId})=>{
    // const [members, setMembers] = useState(null);
    // const [feed, setFeed] = useState(null);
    const classes = useStyles();
    const [abvVsServingTypeData, setAbvVsServingTypeData] = useState(null);
    const [scoreVsBeerNumber, setScoreVsBeerNumber] = useState(null);
    console.log(abvVsServingTypeData);
    useFetch(setAbvVsServingTypeData, setScoreVsBeerNumber, beerId)
    return (
        <Grid container spacing={2} justifyContent="center" alignContent="center" >
            <Grid item xs={8}>
                <Typography>Statistics</Typography>
            </Grid>
            
            {/* {abvVsScoreData &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Typography>Scoring by ABV</Typography>
                        {abvVsScoreData.length !== 0 && <MyResponsiveScatterPlot data={abvVsScoreData}/>}
                        {abvVsScoreData.length === 0 && <Typography>Not enough data yet.</Typography>}
                    </Paper>
                </Grid>
            }  */}
            {abvVsServingTypeData &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Typography>Scoring by serving types.</Typography>
                    
                        {abvVsServingTypeData.length !== 0 && <MyResponsiveSwarmPlot data={abvVsServingTypeData} groupBy="servingTypeString"/>}
                        
                        {/* {abvVsServingTypeData.length !== 0 && <MyResponsiveScatterPlot data={abvVsServingTypeData}/>} */}
                        {abvVsServingTypeData.length === 0 && <Typography>Not enough data yet.</Typography>}
                    </Paper>
                </Grid>
            } 

            {scoreVsBeerNumber &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Typography>Scoring by beer number.</Typography>
                    
                        {scoreVsBeerNumber.length !== 0 && <MyResponsiveSwarmPlot data={scoreVsBeerNumber} groupBy="beerNumber"/>}
                        
                        {/* {abvVsServingTypeData.length !== 0 && <MyResponsiveScatterPlot data={abvVsServingTypeData}/>} */}
                        {scoreVsBeerNumber.length === 0 && <Typography>Not enough data yet.</Typography>}
                    </Paper>
                </Grid>
            } 


        </Grid>
    )
}

export default BeerStatistics;