import React from 'react';
import { Typography, Grid, Chip, Avatar, IconButton, Tooltip, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import TimerIcon from '@mui/icons-material/Timer';
import BeerIcon from '@mui/icons-material/LocalDrink';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import { DeleteReview } from '../Api/BeerApi';
import UserState from '../Atoms/UserAtom';
import ReviewExtraComponents from './ReviewExtraCompoenents';
import { makeStyles  } from '@mui/styles';
import { getDateDiff, getScoreColor } from '../Helpers/GeneralHelperFunctions';


import crispyImage from '../Assets/crispy.png';
import hoppyImage from '../Assets/hoppy.png';
import maltyImage from '../Assets/malty.png';
import roastedImage from '../Assets/roasted.png';
import fruityImage from '../Assets/fruity.png';
import bitterImage from '../Assets/bitter.png';
import sweetImage from '../Assets/sweet.png';
import sourImage from '../Assets/sour.png';
import woodyImage from '../Assets/woody.png';
import creamyImage from '../Assets/creamy.png';
import smokyImage from '../Assets/smoky.png';
import chocolateyImage from '../Assets/chocolatey.png';
import coffeeImage from '../Assets/coffee.png';

const tasteFeatureImages = {
    isCrispy: crispyImage,
    isHoppy: hoppyImage,
    isMalty: maltyImage,
    isRoasted: roastedImage,
    isFruity: fruityImage,
    isBitter: bitterImage,
    isSweet: sweetImage,
    isSour: sourImage,
    isWoody: woodyImage,
    isCreamy: creamyImage,
    isSmoky: smokyImage,
    isChocolatey: chocolateyImage,
    isCoffee: coffeeImage
};



const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default, // Barley Off-White
        color: theme.palette.primary.main, // Amber Ale
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme.palette.secondary.main, // Golden Hops
        }
    },
    chip: { color: theme.palette.primary.contrastText }, // Brew Black
    avatar: {
        width:'100%',
        backgroundColor: theme.palette.primary.main, // Amber Ale
        color: theme.palette.primary.contrastText, // Brew Black
    },
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark
        }
    },
    usernameChip: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.secondary.main, // Golden Hops
        color: theme.palette.secondary.contrastText, // Brew Black
    },
    scoreBadge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 50,
        borderRadius: '50%',
        backgroundColor: props => getScoreColor(props.score),
        color: theme.palette.secondary.contrastText, // Brew Black
        marginRight: theme.spacing(1),
    },
    infoText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    styledPaper: {
        backgroundColor: theme.palette.background.default, 
        color: theme.palette.primary.main,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        transition: 'transform 0.2s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: '0px 8px 20px rgba(0,0,0,0.15)',
        }
    }
}));


const ScoreBadge = ({ score }) => {
    const classes = useStyles();
    return (
        <div className={classes.scoreBadge}>
            <BeerIcon fontSize="small" />
            {score.toFixed(1)}
        </div>
    );
};


const ReviewListItem = ({ review, displayBeerName, identity }) => {
    const classes = useStyles();
    // const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    
    const token = useRecoilValue(UserState);
    
    
    const TasteFeatureChip = ({ feature }) => {
        const { t } = useTranslation();
        return (
            <Chip 
                label={t(`reviewForm.tasteFeatures.${feature}`)} 
                variant="outlined" 
                size="small" 
                className={classes.chip}
                icon={<img src={tasteFeatureImages[feature]} alt={feature} style={{ width: '24px', height: '24px' }} />}
            />
        );
    };
      
    
    const onDelete = () => {
        DeleteReview(review.id, token)
            .then(() => window.location.reload())
            .catch(e => console.error("Error deleting review:", e));
    };

    const extraInfoPresent = [
        "appearanceDescription", "aromaBalance", "aromaDescription",
        "aromaImpression", "aromaIntensity", "clarity", "color", "flavorBalance",
        "flavorDescription", "flavorImpression", "flavorIntensity", "headQuality"
    ].some(key => review[key]);

    console.log(review);
    return (
        <Grid item xs={12}>
            <Paper className={classes.styledPaper} elevation={3}>

                <Grid container spacing={2} alignItems="center">
                    {displayBeerName && (
                        <Grid item xs={12}>
                            <Typography variant="h6">{review.beer.name}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={7}>
                        <Chip 
                            icon={<Avatar className={classes.avatar}>{review.user.username.charAt(0)}</Avatar>}
                            label={review.user.username} 
                            className={classes.usernameChip}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="caption" className={classes.infoText}>
                            <TimerIcon fontSize="small" />
                            <span>{getDateDiff(review.timestamp, t)}</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={2}>
                        {identity && identity.id === review.user.id && (
                            <IconButton onClick={onDelete} className={classes.deleteButton} aria-label="Delete Review">
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <ScoreBadge score={review.score} />
                    </Grid>
                    
                    {review.beerNumber && (
                        <Grid item xs={3}>
                            <Tooltip title={t('beerReviewInfo.general.numberOfBeer')}>
                                <Typography variant="body2" className={classes.infoText}>
                                    <SportsBarIcon fontSize="inherit" />
                                    {review.beerNumber}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    )}
                    {review.servingTypeString && (
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.infoText}>
                                <SportsBarIcon fontSize="inherit" />
                                {t('beerReviewInfo.general.drankFrom')} {t(`beerReviewInfo.general.vessels.${review.servingTypeString}`)}
                            </Typography>
                        </Grid>
                    )}
                    
                    <Grid item xs={12}>
                        {Object.keys(review).filter(key => 
                            key.startsWith('is') && review[key] === true
                        ).map(featureKey => 
                            <TasteFeatureChip key={featureKey} feature={featureKey} />
                        )}
                    </Grid>

                    {extraInfoPresent && (
                        <Grid item xs={12}>
                            <ReviewExtraComponents review={review} />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ReviewListItem;