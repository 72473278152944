import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Paper, TextField, Typography, Snackbar } from '@mui/material';
import UserState from '../../Atoms/UserAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { GetIdentityDescriptor, RegisterApi } from '../../Api/AuthenticationApi';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';


const useStyles = makeStyles({
    paper: {
      padding: '15px',
      marginTop: '20px',
    },
    input: {
      marginBottom: '20px !important', // Adds margin to the bottom of each input
      marginTop: '10px  !important', // Adds margin to the top of each input
    },
    successMessage: {
      padding: '30px',
      marginTop: '20px',
      textAlign: 'center',
    },
  });

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function Register() {
    const classes = useStyles();
    const { t } = useTranslation();

    const token = useRecoilValue(UserState);
    const setUser = useSetRecoilState(UserState);

    const [identity, setIdentity] = useState(null);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        if (token) {
            GetIdentityDescriptor(token).then(result => {
                setIdentity(result);
            });
        }
    }, [token, setUser]);

    const findPasswordError = () => {
        if (password1.length < 8) return "Password must be 8 characters minimum.";
        if (password1 !== password2) return "Passwords must match";
        return "";
    };

    const findEmailError = () => {
        if (!isValidEmail(email)) return "Email is not valid";
        return "";
    };

    const handleClick = () => {
        const passwordError = findPasswordError();
        const emailError = findEmailError();

        if (passwordError || emailError) {
            // Handle errors
            return;
        }

        RegisterApi(userName, password1, email, null)
            .then(() => {
                setRegistrationComplete(true);
            })
            .catch(e => {
                setUserNameError(e.details);
            });
    };

    if (registrationComplete) {
        return (
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={7}>
                    <Paper className={classes.successMessage}>
                        <Typography variant="h4" gutterBottom>
                            Registration Successful!
                        </Typography>
                        <Typography variant="h6">
                            Check your email to find a verification code.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setRegistrationComplete(false)}
                        >
                            Go Back
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    return (
        <div className="register-container">
            {identity && <Navigate to="/" />}
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={7}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" align="center">
                            {t('authentication.register.loginInstead')} 
                            <br />
                            <Link href="login">{t('authentication.register.loginInsteadLink')}</Link>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={7}>
                    <Paper className={classes.paper}>
                        <TextField
                            value={userName}
                            className={classes.input}
                            onChange={ev => setUserName(ev.target.value)}
                            label="Username"
                            variant="outlined"
                            fullWidth
                            error={userNameError !== ""}
                            helperText={userNameError}
                        />
                        <TextField
                            error={findEmailError() !== ""}
                            helperText={findEmailError()}
                            value={email}
                            className={classes.input}
                            onChange={ev => setEmail(ev.target.value)}
                            type="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                        />
                        <Typography variant="caption">{t('authentication.register.passwordRequirements')}</Typography>
                        <TextField
                            error={findPasswordError() !== ""}
                            value={password1}
                            className={classes.input}
                            onChange={ev => setPassword1(ev.target.value)}
                            label={t('authentication.register.passwordPrompt')}
                            type="password"
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            error={findPasswordError() !== ""}
                            helperText={findPasswordError()}
                            value={password2}
                            className={classes.input}
                            onChange={ev => setPassword2(ev.target.value)}
                            label={t('authentication.register.repeatPasswordPrompt')}
                            type="password"
                            variant="outlined"
                            fullWidth
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                            disabled={findPasswordError() !== "" || findEmailError() !== ""}
                        >
                            Register
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message="Check your email to find a verification code"
            />
        </div>
    );
}

export default Register;
