import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Select, MenuItem, Typography, Card, CardContent, Box, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon} from '@mui/icons-material';
import { GetMyHistogram, GetServingTypeList, PostReviewForBeer } from '../Api/BeerApi';
import AppearanceAccordion from './ReviewOptionalParts.js/AppearanceAccordion';
import AromaAccordion from './ReviewOptionalParts.js/AromaAccordion';
import FlavorAccordion from './ReviewOptionalParts.js/FlavorAccordion';
import ReviewScoreSlider from './ReviewComponents/ReviewScoreSlider';
import BeerNumberInput from './ReviewOptionalParts.js/BeerNumberInput';
import CheckCircle from '@mui/icons-material/CheckCircle';

import crispyImage from '../Assets/crispy.png';
import hoppyImage from '../Assets/hoppy.png';
import maltyImage from '../Assets/malty.png';
import roastedImage from '../Assets/roasted.png';
import fruityImage from '../Assets/fruity.png';
import bitterImage from '../Assets/bitter.png';
import sweetImage from '../Assets/sweet.png';
import sourImage from '../Assets/sour.png';
import woodyImage from '../Assets/woody.png';
import creamyImage from '../Assets/creamy.png';
import smokyImage from '../Assets/smoky.png';
import chocolateyImage from '../Assets/chocolatey.png';
import coffeeImage from '../Assets/coffee.png';

const tasteFeatureImages = {
    isCrispy: crispyImage,
    isHoppy: hoppyImage,
    isMalty: maltyImage,
    isRoasted: roastedImage,
    isFruity: fruityImage,
    isBitter: bitterImage,
    isSweet: sweetImage,
    isSour: sourImage,
    isWoody: woodyImage,
    isCreamy: creamyImage,
    isSmoky: smokyImage,
    isChocolatey: chocolateyImage,
    isCoffee: coffeeImage
};


const useStyles = makeStyles({
    paper: {
        backgroundColor: '#204a24',
        padding: '10px',
    },
    topLevelAccordion: {
        backgroundColor: '#282c34', // Dark gray background
        color: 'white',
        borderRadius: '5px',
        margin: '10px 0',
        border: 'none',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Adding a subtle shadow for depth
    },
    secondaryAccordion: {
        backgroundColor: '#f6f6f6', // Light gray
        borderRadius: '5px',
        marginTop: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
    },
    accordionDetails: {
        padding: '15px',
    },
    input: {
        paddingBottom: '15px',
    },
    foo: {
        margin: 'auto',
        width: '50%',
    },
    ReviewFormRoot: {
        marginBottom: '10px',
    },
    heading: {
        fontWeight: 'bold',
    },
    card: {
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    }
});

function ReviewForm(props) {
  const { t } = useTranslation();
  const { beerId, token, beerName, eventStringIdentifier, reroute } = props;

    const [score, setScore] = useState(5);
    const [number, setNumber] = useState(null);
    const [color, setColor] = useState(null);
    const [clarity, setClarity] = useState(null);
    const [headQuality, setHeadQuality] = useState(null);
    const [appearanceDescription, setAppearanceDescription] = useState(null);
    const [aromaIntensity, setAromaIntensity] = useState(null);
    const [aromaBalance, setAromaBalance] = useState(null);
    const [aromaImpression, setAromaImpression] = useState(null);
    const [aromaDescription, setAromaDescription] = useState(null);
    const [flavorIntensity, setFlavorIntensity] = useState(null);
    const [flavorBalance, setFlavorBalance] = useState(null);
    const [flavorImpression, setFlavorImpression] = useState(null);
    const [flavorDescription, setFlavorDescription] = useState(null);
    const [scorings, setScorings] = useState([]);
    const [serveOptionsList, setServeOptionsList] = useState([]);
    const [serveOption, setServeOption] = useState(null);

    const [tasteFeatures, setTasteFeatures] = useState({
        isCrispy: false,
        isHoppy: false,
        isMalty: false,
        isRoasted: false,
        isFruity: false,
        isBitter: false,
        isSweet: false,
        isSour: false,
        isWoody: false,
        isCreamy: false,
        isSmoky: false,
        isChocolatey: false,
        isCoffee: false
    });

    useEffect(() => {
        GetMyHistogram(token)
        .then((result) => {
            console.log(result);
            setScorings(result);
        })
        .catch((error) => {});

        GetServingTypeList()
        .then((result) => {
            setServeOptionsList(result);
        }).catch((error) => {});
    }, [setScorings, setServeOptionsList, token]);



  const handleClick = () => {
    PostReviewForBeer( token, beerId, score, number, color, clarity, headQuality, appearanceDescription || null, aromaIntensity, aromaBalance, aromaImpression, aromaDescription  || null, flavorIntensity, 
        flavorBalance, flavorImpression, flavorDescription || null, serveOption, eventStringIdentifier || null, tasteFeatures
    ).then((data) => {
        if (reroute) {
            window.location = reroute;
        } else {
            window.location.reload();
        }
      }).catch((error) => {});
  };

  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.ReviewFormRoot}>
        <Accordion className={classes.topLevelAccordion}>
            <AccordionSummary expandIcon={<MenuIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>
                    {t('reviewForm.rateTheBeer', { val: beerName })}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={2} justifyContent="center">
                    <ReviewScoreSlider scorings={scorings} setScore={setScore} score={score} beerName={beerName}/>
                    <Grid item xs={12}>
                        <Accordion className={classes.secondaryAccordion}>
                            <AccordionSummary expandIcon={<MenuIcon />} aria-controls="panel1a-content">
                                <Typography className={classes.heading}>{t('reviewForm.more')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Grid container spacing={3} style={{ padding: '24px' }}>

                                    <Grid item xs={12} sm={6}>
                                        <BeerNumberInput setNumber={setNumber} number={number} />
                                    </Grid>

                                    {/* Vessel Selection Section */}
                                    <Grid item xs={12} sm={6}>
                                        <Card elevation={3}>
                                            <CardContent>
                                                <Typography variant="subtitle1" gutterBottom> {t('reviewForm.vessel')} </Typography>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Select
                                                                fullWidth
                                                                value={serveOption}
                                                                onChange={(ev) => setServeOption(ev.target.value)}
                                                                variant="outlined"
                                                            >
                                                            {serveOptionsList.map((opt) => (
                                                                <MenuItem key={opt.description} value={opt.id}>
                                                                    {t(`beerReviewInfo.general.vessels.${opt.description}`)}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {serveOption !== null && (
                                                            <Button variant="contained" color="secondary" onClick={() => setServeOption(null)}>Clear</Button>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <Card elevation={3}>
                                            <CardContent>
                                                <Typography variant="subtitle1" gutterBottom> 
                                                    {t('reviewForm.tasteFeatures.title')}
                                                </Typography>
                                                <Box display="flex" justifyContent="start" flexWrap="wrap" gap={1}>
                                                    {Object.keys(tasteFeatures).map(feature => (
                                                        <Chip
                                                            key={feature}
                                                            label={t(`reviewForm.tasteFeatures.${feature}`)}
                                                            clickable
                                                            color={tasteFeatures[feature] ? "primary" : "default"}
                                                            icon={tasteFeatures[feature] ? <CheckCircle /> : <img src={tasteFeatureImages[feature]} alt={feature} style={{ width: '24px', height: '24px' }} />}
                                                            onClick={() => {
                                                                setTasteFeatures(prev => ({
                                                                    ...prev,
                                                                    [feature]: !prev[feature]
                                                                }));
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>



                                </Grid>
                                <AppearanceAccordion
                                    color={color} setColor={setColor}
                                    clarity={clarity} setClarity={setClarity}
                                    headQuality={headQuality} setHeadQuality={setHeadQuality}
                                    appearanceDescription={appearanceDescription} setAppearanceDescription={setAppearanceDescription}
                                    />
                                <AromaAccordion
                                    aromaIntensity={aromaIntensity} setAromaIntensity={setAromaIntensity}
                                    aromaBalance={aromaBalance} setAromaBalance={setAromaBalance}
                                    aromaImpression={aromaImpression} setAromaImpression={setAromaImpression}
                                    aromaDescription={aromaDescription} setAromaDescription={setAromaDescription}
                                    />
                                <FlavorAccordion
                                    flavorIntensity={flavorIntensity} setFlavorIntensity={setFlavorIntensity}
                                    flavorBalance={flavorBalance} setFlavorBalance={setFlavorBalance}
                                    flavorImpression={flavorImpression} setFlavorImpression={setFlavorImpression}
                                    flavorDescription={flavorDescription} setFlavorDescription={setFlavorDescription}
                                    />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={2}>
                        
                        <Button fullWidth variant="contained" color="primary" onClick={handleClick}>Post review</Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </Grid>
  );
}

export default ReviewForm;