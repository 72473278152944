import { Button, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";
import { makeStyles } from "@mui/styles";
import {AddBeerToEvent, GetBeersForEvents, RemoveBeerFromEvent, ReorderBeers} from '../../Api/EventApi'
import {useTranslation} from 'react-i18next';
import { Autocomplete, TextField, Typography } from '@mui/material';
import {GetBeers} from '../../Api/BeerApi';
import {AlphabeticalBeerSort} from '../../Helpers/AlphabeticalSort';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const eventSort = (a, b) => a.order - b.order;

const useStyles = makeStyles({
    paper: {
        padding: '15px'
    },
    listContainer: {
        marginLeft: "5px",
        marginRight: "5px",
        width: '90%'
    },
    itemContainer: {
        backgroundColor: "#FAFAFA",
        color:"black",
        height: '60px',
        marginBottom: "10px",
        padding: '5px 5px 5px 5px',
        borderRadius: '5px'
        // width: '100%'
    }
});

const useFetch = (eventId, setBeers, setEventBeers, token)=>{useEffect(() => {
    if(token == null){ return; }
    
    GetBeers().then(result=>{
        result.sort(AlphabeticalBeerSort);
        setBeers(result);
    }).catch(error=>{
    
    });

    GetBeersForEvents(token, eventId).then(result => {
        result.sort(eventSort);
        setEventBeers(result);
    }).catch(error=>{

    });

}, [eventId, setBeers, setEventBeers, token])}


const EventAdministration = ({eventId})=>{
    const {t} = useTranslation();
    const [selection, setSelection] = useState(null);
    const [beers, setBeers] = useState([])
    const [eventBeers, setEventBeers] = useState([])
    const classes = useStyles();
    const token = useRecoilValue(UserState);
    
    
    useFetch(eventId, setBeers, setEventBeers, token)
    

// React state to track order of items

    const submitBeer = ()=>{
        AddBeerToEvent(token, eventId, selection.id).then(()=>{
            GetBeersForEvents(token, eventId).then(result => {
                result.sort(eventSort);
                setEventBeers(result);
            }).catch(error=>{
        
            });
        }).catch();
    }

    const removeBeer = (beerId) =>{
        RemoveBeerFromEvent(token, eventId, beerId).then(()=>{
            GetBeersForEvents(token, eventId).then(result => {
                result.sort(eventSort);
                setEventBeers(result);
            }).catch(error=>{
        
            });
        })
    }



    // React state to track order of items

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedEventBeerList = [...eventBeers];
        console.log(eventBeers.map(x=>`${x.name} -- ${x.order}`));
        // Remove dragged item
        let [reorderedItem] = updatedEventBeerList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedEventBeerList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedEventBeerList.forEach((el, index)=>{
            updatedEventBeerList[index].order = index;
        })

        console.log(updatedEventBeerList.map(x=>`${x.name} -- ${x.order}` ));
        // Update state in the backedn first.
        setEventBeers(updatedEventBeerList)
        ReorderBeers(token, eventId, updatedEventBeerList).then(data=>{
            setEventBeers(data.sort(eventSort));    
        }).catch(error=>{
            console.log(error);
        })
    };


    return (
        <Grid container spacing={2} justifyContent="center" >
            
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}>
                        <Grid item xs={12}>
                            {t('events.addBeerToEvent')}

                            <Autocomplete  className={classes.search}
                                freeSolo
                                id="combo-box-demo"
                                options={beers}
                                getOptionLabel={(beer)=>beer.name}
                                onChange={(event, newInputValue) => {
                                    setSelection(newInputValue);
                                }}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={t("searchForBeer")
                                } />}
                            /> 
                            <br />
                            {selection && <Grid item xs={11} container spacing={2}>
                                <Grid item xs={6}><Typography>Add {selection.name} to the event</Typography></Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={submitBeer} >Add</Button> 
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => setSelection(null)}>Cancel</Button>
                                </Grid>
                            </Grid>}
                            <br />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>


            {eventBeers && <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container" >
                    
                    {(provided) => (
                        <Grid container item xs={12} className={classes.listContainer} {...provided.droppableProps} ref={provided.innerRef}>

                            {eventBeers?.map((beer, index) => (
                                <Draggable key={beer.id} draggableId={""+beer.id} index={index} >
                                    {(provided) => ( <Grid container ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} className={classes.itemContainer}>
                                        <Grid item xs={12} container justifyContent="space-around" spacing={1} >
                                        
                                            <Grid item xs={1}>
                                                <DragIndicatorIcon />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="caption">{beer.name}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button fullWidth size='small' variant="contained" href={`/event/${eventId}/review/${beer.id}`}>Review</Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button fullWidth size='small' variant="contained" color="warning" onClick={()=>{removeBeer(beer.id)}}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>)}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}

                    </Droppable>
                </DragDropContext>
            }

        </Grid>
    )
}

export default EventAdministration;