import settings from './apiSettings';

export function RegisterApi(Username, Password, Email, facebookId){
    return fetch(`${settings.host}/Authentication/register`,
    {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify({Username: Username, Password: Password, Email: Email, FacebookId: facebookId ? facebookId:null })
    }
    ).then(async(response)=>{
        if(response.ok){
            return response;
        } else {
            throw await response.json();
        }
    });
}

export function GetIdentityDescriptor(token){
    return fetch(`${settings.host}/Authentication/identity`, {
        headers: {'authorization':`bearer ${token}`}
    }).then(async response=>{
        if(response.ok){
            return await response.json()
        } else {
            throw  await response.json();
        }
    })
}

export function Authenticate (Email, Password){
    console.log(Email, Password);
    return fetch(`${settings.host}/Authentication/authenticate`,
    {
        method: 'POST', 
        // mode: 'cors',
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify({Email: Email, Password: Password})
    }
    ).then(async (response)=>{
        if(response.ok){
            return await response.json()
        } else {
            throw await response.json();
        }
    })
}



export function SendPasswordResetRequest(email){
    return fetch(`${settings.host}/Authentication/passwordReset/${email}`,
    {
        method: 'GET'
    }
    ).then(async (response)=>{
        if(!response.ok){
            throw await response.json();
        }
    })
}
export function SendResetPassword(resetToken, newPassword){
    return fetch(`${settings.host}/Authentication/passwordReset`,
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify({resetToken, newPassword})
    }
    ).then(async (response)=>{
        if(!response.ok){
            throw await response.json();
        }
    })
}
export function FacebookAuthenticate (facebookId){
    return fetch(`${settings.host}/Authentication/facebookAuthenticate`,
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify({FacebookId: facebookId})
    }
    ).then(async (response)=>{
        if(response.ok){
            return await response.json()
        } else {
            throw await response.json();
        }
    
    })
}

export function GetNotifications(token) { 
    return fetch(`${settings.host}/Groups/invite`, {
        headers: {'authorization':`bearer ${token}`}
    }).then(async response=>{
        if(response.ok){
            return await response.json()
        } else {
            throw  await response.json();
        }
    })
}

export function GetMyGroups(token){
    return fetch(`${settings.host}/Groups`, {
        headers: {'authorization':`bearer ${token}`}
    }).then(async response=>{
        return await response.json()
    })
}

export function CreateGroup(token, title){
    return fetch(`${settings.host}/Groups`, {
        method: 'POST',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify({'title':title})
    }).then(async response=>{
        if(!response.ok){
         throw  await response.json();
        }
    })
}

export function VerifyUser(token){
    return fetch(`${settings.host}/Authentication/verify/${token}`, {
        method: 'POST'
    }).then(async response=>{
        if(!response.ok){
         throw  await response.json();
        }
    })
}

export function GetUserStatistics(token){
    return fetch(`${settings.host}/Authentication/info/statistics`, {
        method: 'GET',
        headers: {'authorization':`bearer ${token}`, 'Content-Type': 'application/json'}
    }).then(async response=>{
        if(!response.ok){
            throw await response.json();
        } else {
            return await response.json();
        }
    })
}
// export function GetGroupMembers(token, groupId){
//     return fetch(`${settings.host}/Groups/${groupId}/members`, {
//         headers: {'authorization':`bearer ${token}`}
//     }).then(async response=>{
//         return await response.json()
//     })
// }

// export function GetGroupFeed(token, groupId){
//     return fetch(`${settings.host}/Groups/${groupId}/feed`, {
//         headers: {'authorization':`bearer ${token}`}
//     }).then(async response=>{
//         return await response.json()
//     })
// }

// export default AuthenticationApi;