import { Grid,Paper} from '@mui/material';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import UserState from '../Atoms/UserAtom';
import { useRecoilValue } from 'recoil';
import { GetBreweryBeerList, GetBreweryBeerListForUser } from '../Api/BeerApi';
import UserBeerDataTable from '../Components/DataTables/UserBeerDataTable';
import BeerDataTable from '../Components/DataTables/BeerDataTable';
import BreweryInfo from '../Components/InfoComponents/BreweryInfo';


function BreweryProfile() {
    const {id} = useParams();
    // const [brewery, setBrewery] = useState(null);
    const [beers, setBeers] = useState(null);

    const token = useRecoilValue(UserState);
    useEffect(() => {
        if(token){
    
            GetBreweryBeerListForUser(id, token).then(result=>{
                console.log("setting beers");
                setBeers(result);
            }).catch(error=>{
        
            });
    
    
        } else {
    
            GetBreweryBeerList(id).then(result=>{
                setBeers(result);
            }).catch(error=>{
        
            });
        }
    }, [token, id, setBeers])

    return (
        <Grid container justifyContent="center" direction="column" >
            <BreweryInfo breweryId={id} />
            <Paper>
                {token && beers && <UserBeerDataTable beers={beers}/>}
                {!token && beers && <BeerDataTable beers={beers}/>}
                
            </Paper>
        </Grid>
    );
}

export default BreweryProfile;
