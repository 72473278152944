
import {  Grid, Slider, Typography, Button, Box} from '@mui/material';
import { useTranslation } from 'react-i18next';


function NullableSlider(props) {
    const { value, setValue, chapter, thing } = props;
    const { t } = useTranslation();

    return (
        <Box mb={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                        {t(`reviewForm.accordions.${chapter}.${thing}.title`)}
                        {value === null && ` - ${t('reviewForm.noScore')}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    {value === null ? (
                        <Button variant='contained' color="primary" onClick={() => { setValue(3) }}>
                            {t(`reviewForm.prompt`)}
                        </Button>
                    ) : (
                        <Button variant='contained' color='error' onClick={() => { setValue(null) }}>
                            Remove
                        </Button>
                    )}
                </Grid>
                {value !== null && (
                    <Grid item xs={12}>
                        <Slider
                            valueLabelDisplay="auto"
                            value={value}
                            onChange={(ev, newValue) => { setValue(Number(newValue)) }}
                            marks={[
                                { value: 0, label: t(`reviewForm.accordions.${chapter}.${thing}.low`) },
                                { value: 3, label: t(`reviewForm.accordions.${chapter}.${thing}.mid`) },
                                { value: 6, label: t(`reviewForm.accordions.${chapter}.${thing}.high`) }
                            ]}
                            min={0}
                            max={6}
                            step={1}
                            aria-label="Rating Slider"
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default NullableSlider;
