import { Grid, Paper} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";
import {GetEventStatistics} from '../../Api/EventApi';
import {Typography } from '@mui/material';
import MyResponsiveSwarmPlot from "../../Components/Stats/MyResponsiveSwarmPlot";
import { GetScoreVsABVGroupByTypes } from "../../Helpers/StatsPromises";
import MyResponsiveScatterPlot from "../../Components/Stats/MyResponsiveScatterPlot";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    paper: {
        padding: '15px',
        marginBottom: '10px'
    }
});

const EventStatistics = ({eventId})=>{
    const classes = useStyles();
    
    const [abvVsScoreData, setAbvVsScoreData] = useState(null);
    const [rawData, setRawData] = useState(null);


    const token = useRecoilValue(UserState);
    


    useEffect(() => {
        if(token == null){ return; }
        GetEventStatistics(token, eventId).then(data => {
            setRawData(data)
            // GetScoreVsABV(data).then(d=>{setAbvVsScoreData(d);})
            GetScoreVsABVGroupByTypes(data).then(d=>{setAbvVsScoreData(d)})
        }).catch(error=>{
            console.log(error);
        })
    
    }, [eventId, setRawData, setAbvVsScoreData, token])


    return (
        <Grid container spacing={2} justifyContent="center" alignContent="center" >
            <Grid item xs={8}>
                <Typography>Scoring habits by event</Typography>
            </Grid>

            {abvVsScoreData &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        
                        <Typography>Event scoring by ABV</Typography>
                        {(abvVsScoreData.length === 0) && <Typography>Not enough data yet.</Typography>}
                        {(abvVsScoreData.length >= 1) && <MyResponsiveScatterPlot data={abvVsScoreData}/>}
                    </Paper>
                  
                </Grid>
            } 

            {/* https://nivo.rocks/swarmplot */}
            {rawData &&
                <Grid item xs={12} >
                    <Paper  className={classes.paper}>
                        <Typography>Event scoring by beers</Typography>
                        { (rawData.length) === 0 && <Typography>Not enough data yet</Typography>}
                        {(rawData.length >= 1) && <MyResponsiveSwarmPlot data={rawData} groupBy="beerName"/>}
                    </Paper>
                  
                </Grid>
            } 

        </Grid>
    )
}

export default EventStatistics;