export function getScoreColor (score){
    const red = [255, 204, 204];  // light-red
    const green = [204, 255, 204];  // light-green
    const factor = score / 10;
    return interpolateColor(red, green, factor);
};

export function interpolateColor(color1, color2, factor){
    const r = Math.round(color1[0] + factor * (color2[0] - color1[0]));
    const g = Math.round(color1[1] + factor * (color2[1] - color1[1]));
    const b = Math.round(color1[2] + factor * (color2[2] - color1[2]));
    return `rgb(${r},${g},${b})`;
};

export function getDateDiff  (timestamp, t){
    const dateCreated = new Date(timestamp);
    const delta = new Date() - dateCreated;
    const weeksDelta = delta / (1000 * 60 * 60 * 24 * 7);
    const daysDelta = delta / (1000 * 60 * 60 * 24);
    const hoursDelta = delta / (1000 * 60 * 60);
    const minutesDelta = delta / (1000 * 60);
    
    if (weeksDelta >= 1) {
        return t('beerReviewInfo.general.timeAgo.weeksAgo', { val: Math.round(weeksDelta) });
    } else if (daysDelta >= 1) {
        return t('beerReviewInfo.general.timeAgo.daysAgo', { val: Math.round(daysDelta) });
    } else if (hoursDelta >= 1) {
        return t('beerReviewInfo.general.timeAgo.hoursAgo', { val: Math.round(hoursDelta) });
    } else {
        return t('beerReviewInfo.general.timeAgo.minutesAgo', { val: Math.round(minutesDelta) });
    }
};

