import MenuIcon from '@mui/icons-material/Menu';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Slider, Grid, Paper, Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';

function ReviewExtraComponents({ review }) {
    const { t } = useTranslation();

    const DisplaySlider = ({ chapter, type, value }) => value !== null && (
        <Grid item xs={10} container spacing={2}>
            <Grid item xs={5}>
                <Typography variant="button">
                    {t(`reviewForm.accordions.${chapter}.${type}.title`)}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Slider valueLabelDisplay="off"
                    value={value} disabled
                    marks={[
                        { value: 0, label: t(`reviewForm.accordions.${chapter}.${type}.low`) },
                        { value: 3, label: t(`reviewForm.accordions.${chapter}.${type}.mid`) },
                        { value: 6, label: t(`reviewForm.accordions.${chapter}.${type}.high`) }
                    ]}
                    min={0} max={6} step={1} />
            </Grid>
        </Grid>
    );

    const DisplayDescription = ({ chapter, description }) => description && (
        <Grid item xs={5}>
            <Typography variant="caption">
                {t(`reviewForm.accordions.${chapter}.moreDetail`)}
            </Typography>
            <Paper><Typography>"{description}"</Typography></Paper>
        </Grid>
    );

    return (
        <Accordion>
            <AccordionSummary expandIcon={<MenuIcon />} aria-controls="panel1a-content">
                <Typography>{t('reviewForm.accordions.moreDetail')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} justifyContent="center">
                    {/* LOOKS SECTION */}
                    {(review.color || review.clarity || review.headQuality || review.appearanceDescription) && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('reviewForm.accordions.looks.title')}</Typography>
                            </Grid>
                            {review.color && (
                                <>
                                    <Grid item xs={5}>
                                        <Typography variant="button">{t('reviewForm.accordions.looks.color')}</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography>{review.color}</Typography>
                                    </Grid>
                                </>
                            )}
                            <DisplaySlider chapter='looks' type='opacity' value={review.clarity} />
                            <DisplaySlider chapter='looks' type='head' value={review.headQuality} />
                            <DisplayDescription chapter="looks" description={review.appearanceDescription} />
                            <Divider />
                        </>
                    )}
                    {/* AROMA SECTION */}
                    {(review.aromaIntensity || review.aromaBalance || review.aromaImpression || review.aromaDescription) && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('reviewForm.accordions.aroma.title')}</Typography>
                            </Grid>
                            <DisplaySlider chapter="aroma" type="strength" value={review.aromaIntensity} />
                            <DisplaySlider chapter="aroma" type="balance" value={review.aromaBalance} />
                            <DisplaySlider chapter="aroma" type="quality" value={review.aromaImpression} />
                            <DisplayDescription chapter="aroma" description={review.aromaDescription} />
                            <Divider />
                        </>
                    )}
                    {/* TASTE SECTION */}
                    {(review.flavorBalance || review.flavorDescription || review.flavorImpression || review.flavorIntensity) && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('reviewForm.accordions.taste.title')}</Typography>
                            </Grid>
                            <DisplaySlider chapter="taste" type="strength" value={review.flavorIntensity} />
                            <DisplaySlider chapter="taste" type="balance" value={review.flavorBalance} />
                            <DisplaySlider chapter="taste" type="quality" value={review.flavorImpression} />
                            <DisplayDescription chapter="taste" description={review.flavorDescription} />
                        </>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default ReviewExtraComponents;
