import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const ImageInput = ({ onImageChange }) => {
    const [preview, setPreview] = useState(null);

    const handleFileChange = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                onImageChange(reader.result);
                setPreview(reader.result);  // Set the preview
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (event) => {
                    onImageChange(event.target.result);
                    setPreview(event.target.result);  // Set the preview
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    return (
        <Box mb={2} display="flex" alignItems="center">
            {/* Input Area */}
            <Box flex={1}>
                <Box
                    onPaste={handlePaste}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                        e.preventDefault();
                        handleFileChange(e.dataTransfer.files);
                    }}
                    style={{
                        border: '2px dashed gray',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Typography variant="body1">Drag & Drop or Paste an Image</Typography>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e.target.files)}
                        id="hiddenFileInput"
                    />
                    <label htmlFor="hiddenFileInput"></label>
                </Box>
            </Box>

            {/* Image Preview */}
            {preview && (
                <Box ml={2}>
                    <img src={preview} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                </Box>
            )}
        </Box>
    );
};

export default ImageInput;