import React, { useEffect, useState } from 'react';
import { GetUsers } from '../../Api/AdminApi';
import { Avatar, Card, CardContent, CardHeader, Grid, IconButton, Typography, Tooltip, Chip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

function UserAdministration({ token }) {
    const [users, setUsers] = useState(null);

    useEffect(() => {
        GetUsers(token).then(x => {
            setUsers(x);
        });
    }, [token]);

    return (
        <Grid container spacing={3}>
            {users && users.map(user => (
                <Grid item xs={12} md={6} lg={4} key={user.id}>
                    <Card elevation={3}>
                        <CardHeader
                            avatar={
                                <Avatar>{user.username[0].toUpperCase()}</Avatar>
                            }
                            action={
                                <>
                                    {user.isAdministrator && (
                                        <Tooltip title="Administrator">
                                            <IconButton>
                                                <AdminPanelSettingsIcon color="secondary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            }
                            title={user.username}
                            subheader={user.email}
                        />
                        <CardContent>
                            {/* Displaying the "Verified" tag if the user is verified */}
                            {user.isVerified && (
                                <Chip 
                                    icon={<VerifiedUserIcon />} 
                                    label="Verified" 
                                    variant="outlined" 
                                    color="primary" 
                                    size="small"
                                    style={{ marginBottom: 8 }}
                                />
                            )}
                            <Typography variant="body2" color="textSecondary">
                                {user.isAdministrator ? "Admin User" : "Regular User"}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {!users && (
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">Loading users...</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default UserAdministration;
