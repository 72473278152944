
import { Accordion, AccordionDetails, AccordionSummary, Grid, ToggleButton, ToggleButtonGroup, Typography, Box} from '@mui/material';
import { useTranslation } from 'react-i18next';
import NullableSlider from './NullableSlider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const colorOptions = [
    {value: "straw",      color : '#ffe186'},
    {value: "gold",       color : '#fecb30'},
    {value: "amber",      color : '#e2a30f'},
    {value: "copper",     color : '#986e0a'},
    {value: "darkbrown",  color : '#5e4406'},
    {value: "black",      color : '#1b1403'},
    {value: "red",        color : "#8c1212"}
]
function AppearanceAccordion(props) {
    const {color, setColor, clarity, setClarity, headQuality, setHeadQuality} = props;
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography variant="h6">{t('reviewForm.accordions.looks.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1">{t('reviewForm.accordions.looks.color')}</Typography>
                        <Box mt={1}>
                            <ToggleButtonGroup exclusive onChange={(ev) => { setColor(ev.target.value) }} value={color} fullWidth>
                                {colorOptions.map(o => (
                                    <ToggleButton key={o.value} value={o.value} style={{ backgroundColor: o.color, color: "white", height: '35px' }}>
                                        {color === o.value && "🗸"}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <NullableSlider value={clarity} setValue={setClarity} chapter="looks" thing="opacity" />
                        <NullableSlider value={headQuality} setValue={setHeadQuality} chapter="looks" thing="head" />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('reviewForm.accordions.moreDetail')}</Typography>
                        <Box mt={1}>
                            <TextField fullWidth value={appearanceDescription} onChange={ev => { setAppearanceDescription(ev.target.value) }} variant="outlined" />
                        </Box>
                    </Grid> */}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AppearanceAccordion;

