import React from 'react';
import { Button, Grid, Input, InputLabel, Paper} from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AddCsvFile } from '../../Api/AdminApi';


const useStyles = makeStyles({
    paper: {
        padding: '15px',
        margin: '10px'
      //   marginBottom: '10px'
    },
    msgDiv:{
      padding: '10px',
      margin: 'auto',
      alignContent: 'center'
    }
});
  

function MassAdds({token}) {

    const handleSubmit = async (file, endpoint) => {
        console.log(file);
        try {
            let data = await AddCsvFile(token, endpoint, file);
            console.log(data);
            setMessages(data.problems)
        }
        catch (error){
            console.error(error);
        }
    };

    const classes = useStyles();

    const [messages, setMessages] = useState([]);
    const [beerCsvFile, setBeerCsvFile] = useState("");
    const [manufacturersFile, setManufacturerCsvFile] = useState("");
    const [countriesCsvFile, setCountriesCsvFile] = useState("");
    const [beerTypesFile, setBeerTypesCsvFile] = useState("");



    return (
        <>
            <Paper className={classes.paper}>
                <Grid item xs={6}>Mass imports</Grid>
            </Paper>

            <Grid item xs={12} container>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputLabel>Input Beers from a csv file.</InputLabel>
                        <Input type="file" onChange={(e) => { setBeerCsvFile(e.target.files[0]) }} />
                        <Button onClick={()=>{ handleSubmit(beerCsvFile, 'beersfromcsv') }}>Submit</Button>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputLabel>Input Manufacturers from a csv file.</InputLabel>
                        <Input type="file" onChange={(e) => { setManufacturerCsvFile(e.target.files[0]) }} />
                        <Button onClick={()=>{ handleSubmit(manufacturersFile, 'manufacturersfromcsv') }}>Submit</Button>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputLabel>Input Countries from a csv file.</InputLabel>
                        <Input type="file" onChange={(e) => { setCountriesCsvFile(e.target.files[0]) }} />
                        <Button onClick={()=>{ handleSubmit(countriesCsvFile, 'countriesfromcsv') }}>Submit</Button>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputLabel>Input beer types from a csv file.</InputLabel>
                        <Input type="file" onChange={(e) => { setBeerTypesCsvFile(e.target.files[0]) }} />
                        <Button onClick={()=>{ handleSubmit(beerTypesFile, 'beertypesfromcsv') }}>Submit</Button>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    Here's a log
                    <Paper style={{color: 'white', backgroundColor:'gray'}}>
                        {messages.map(message => <div key={message} className={classes.msgDiv}>~{message}</div>)}
                    </Paper>
                </Paper>
            </Grid>
        </>
    );
}

export default MassAdds;
