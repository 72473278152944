import { Grid, Paper} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import UserState from "../../Atoms/UserAtom";


import {Typography } from '@mui/material';
import MyResponsiveSwarmPlot from "../../Components/Stats/MyResponsiveSwarmPlot";
import { GetScoreVsABVGroupByTypes } from "../../Helpers/StatsPromises";
import MyResponsiveScatterPlot from "../../Components/Stats/MyResponsiveScatterPlot";
import { GetUserStatistics } from "../../Api/AuthenticationApi";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
    paper: {
        padding: '15px',
        marginBottom: '10px'
    }
});



const useFetch = (setRawData, setAbvVsScoreData, token)=>{useEffect(() => {
    if(token == null){ return; }

    GetUserStatistics(token).then(data => {
        setRawData(data)
        GetScoreVsABVGroupByTypes(data).then(d=>{setAbvVsScoreData(d);})
    }).catch(error=>{
        console.log(error);
    })

}, [setRawData, setAbvVsScoreData, token])}




const UserStatistics = ()=>{
    // const [members, setMembers] = useState(null);
    // const [feed, setFeed] = useState(null);
    
    const classes=useStyles();
    
    const token = useRecoilValue(UserState);

    const [abvVsScoreData, setAbvVsScoreData] = useState(null);
    
    const [rawData, setRawData] = useState(null);

    useFetch(setRawData, setAbvVsScoreData, token)
    return (
        <Grid container spacing={2} justifyContent="center" alignContent="center" >
            <Grid item xs={8}>
                <Typography>Your statistics</Typography>
            </Grid>

            {abvVsScoreData &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography>Your scoring by ABV</Typography>
                        {(abvVsScoreData.length === 0) && <Typography >Not enough data yet</Typography>}
                        {(abvVsScoreData.length >= 1) && <MyResponsiveScatterPlot data={abvVsScoreData}/>}
                    </Paper>
                  
                </Grid>
            } 

            {/* https://nivo.rocks/swarmplot */}
            {rawData &&
                <Grid item xs={12} >
                    <Paper className={classes.paper}>
                        <Typography>Your beer scoring habits</Typography>
                        
                        {(rawData.length === 0) && <Typography >Not enough data yet</Typography>}
                        {(rawData.length >= 1) &&  <MyResponsiveSwarmPlot data={rawData} groupBy="beerName"/>}
                       
                    </Paper>
                  
                </Grid>
            } 

        </Grid>
    )
}

export default UserStatistics;