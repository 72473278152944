import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Link, useMediaQuery} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreweryLogoContainer from '../InfoComponents/BreweryLogoContainer';

function strRound(n){ return Math.floor(n*100)/100}


const useStyles = makeStyles({
    bar: {
        width: '100%',
        marginBottom: '15px',
    },
    toolBar: {
        backgroundColor: '#204a24', // green
        backgroundPositionY: '140px',
        backgroundRepeat: 'repeat',
        backgroundSize: '215px',
        background: '#FFFFFF'
    },
    hrefButton: { 
        color: 'white !important',
        // backgroundColor: 'green !important'
    }, 
    hrefButtonBold: {
        fontWeight: 'bold'
    },
    mobileTd: {
        fontSize: '35% !important',
        paddingLeft: '1px',
        paddingRight: '1px',
        marginLeft: '1px',
        marginRight: '1px'
    },
    breweryLogoContainer: {
        width: '50px',  // Adjust as needed
        height: '50px',  // Adjust as needed
        marginRight: '10px',  // Space between the logo and the beer name
        display: 'inline-block',
        verticalAlign: 'middle',  // Aligns the logo with the middle of the text
        borderRadius: '50%',  // Makes the container circular
        overflow: 'hidden',  // Ensures that the image doesn't spill out of the circular container
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
});

function BeerDataTable(props) {
    const {beers}=props;
    const classes = useStyles();
    console.log(beers);
    const { t } = useTranslation();
    const isMobile = !useMediaQuery('(min-width:900px)');
    

    return (
        <Grid container item xs={12} justifyContent="center" spacing={2}>
            <Grid item xs={12}>
            <Paper>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={isMobile?classes.mobileTd:""} >{t('beer.name')}</TableCell>
                            {isMobile && <TableCell className={isMobile?classes.mobileTd:""}  align="center">{isMobile?"🏭":t("beer.brewery")}</TableCell> }
                            <TableCell className={isMobile?classes.mobileTd:""}  align="center">{t('beer.type')}</TableCell>
                            <TableCell className={isMobile?classes.mobileTd:""}  align="center">%</TableCell>
                            <TableCell className={isMobile?classes.mobileTd:""}  align="center">{t('beer.avgScore')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {beers.map((beer) => (
                        <TableRow key={beer.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >


                            <TableCell 
                                className={`${classes.td} ${classes.cellWithIcon}`} 
                                component="th" align="left" scope="row"
                                >
                                    {/* <div className={classes.breweryLogoContainer} style={{backgroundImage: `url(${beer.brewery.b64Logo})`}}></div> */}
                                    <BreweryLogoContainer breweryId={beer.brewery.id} />
                                <Link  href={`/beer/${beer.id}`}>{beer.name}</Link>
                            </TableCell>



                            <TableCell className={isMobile?classes.mobileTd:""} component="th" align="left" scope="row"> <Link href={`/beer/${beer.id}`}>{beer.name}</Link>  </TableCell>
                            {isMobile && <TableCell className={isMobile?classes.mobileTd:""} component="th" align="center" scope="row">  <Link href={`/brewery/${beer.manufacturer.id}`}>{beer.manufacturer.name}</Link></TableCell> }
                            <TableCell className={isMobile?classes.mobileTd:""} component="th" align="center" scope="row"> <Link href={`/type/${beer.typeId}`}>{beer.typeName}</Link></TableCell>
                            <TableCell className={isMobile?classes.mobileTd:""} component="th" align="center" scope="row"> {Number(beer.abv).toFixed(2)}%</TableCell>
                            <TableCell className={isMobile?classes.mobileTd:""} component="th" align="center" scope="row"> {beer.numTotalReviews !== 0? `${strRound(beer.globalAverageScore)}/${beer.numTotalReviews}`:''}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            </Grid>
        </Grid>
    );
}

export default BeerDataTable;
