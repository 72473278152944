import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow,  FormControlLabel, Checkbox, Link} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {  useState } from 'react';
import {useMediaQuery} from '@mui/material'
import { makeStyles } from '@mui/styles';
import BreweryLogoContainer from '../InfoComponents/BreweryLogoContainer';

const bcbc = "black"
const bcbd = 0.01
const bcbw = 1

const useStyles = makeStyles((theme) => ({
    largeTableText: {
        fontSize: '1.2em', // Adjust this value to your preference
    },
    textOverlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.6)', // semi-transparent white
        padding: '2px 5px', // some padding to give it space
        borderRadius: '5px', // rounded corners
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', // subtle shadow
    },
    td: {
        paddingLeft: '0px',
        paddingRight: '0px',
        marginLeft: '1px',
        marginRight: '1px',
        backgroundColor: theme.palette.background.paper, // Using the theme's paper background color
        color: theme.palette.text.primary, // Using the theme's primary text color
    },
    beerIcon: {
        position: 'absolute',
        top: '1px',  // Adjust as needed
        left: '1px',  // Adjust as needed
        fontSize: '1.5em',  // Adjust as needed
        opacity: '100%',
        textShadow: `
        0 0 ${bcbw}px ${bcbc}, 
        -${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}, 
        -${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}
        `
    },
    cellWithIcon: {
        position: 'relative',  // This is important for absolute positioning of child elements
    },
    boldLink: {
        fontSize: "1.6em",
        fontWeight: 'bold !important',
        color: 'white !important',
        textDecoration: 'none',  // Removes the default underline
        '&:hover': {
            textDecoration: 'underline',  // Adds underline on hover
        },
        WebkitTextStroke: '1px black'
        //   -webkit-text-stroke-width: 3px;
        //   -webkit-text-stroke-color: black;
    },
    shadowedText: {
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',  // Adds a subtle shadow
    }
}));
function strRound(n){ return Math.floor(n*100)/100}

const absortFn = (a,b)=>{
    if(a.name > b.name){ return 1 }
    if(a.name < b.name){ return -1 }
    return 0;
}




function UserBeerDataTable(props) {
    const {beers}=props;
    // console.log(beers);
    
    const { t } = useTranslation();
    const [displayBeers, setDisplayBeers] = useState(beers.sort(absortFn));
    
    const classes= useStyles();
    const [showReviewedBeers, setShowReviewedBeers] = useState(true);
    
    function handleFilterChange(){
        setShowReviewedBeers(!showReviewedBeers)
        setDisplayBeers(beers.filter(i=> (!showReviewedBeers||i.numReviewsByUser===0)).sort(absortFn))
    }
    const isMobile = !useMediaQuery('(min-width:900px)');
    
    
    
      

    console.log(displayBeers)
    return (
        // <div style={{width:'100%'}}>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox checked={showReviewedBeers} onChange={handleFilterChange} name="gilad" />
                    }
                    label={t('beer.showBeersReviewedByYou')}
                    />
            </Grid>
            <Grid item xs={12}>
            <Paper>
                <Table  size="small"  aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.td} >{t('beer.name')}</TableCell>
                            {!isMobile && <TableCell className={classes.td}  align="center">{isMobile?"🏭":t("beer.brewery")}</TableCell> }
                            <TableCell className={classes.td}  align="center">{t('beer.type')}</TableCell>
                            <TableCell className={classes.td}  align="center">%</TableCell>
                            {!isMobile && <TableCell className={isMobile?classes.mobileTd:""}  align="center">{t('beer.avgScore')}</TableCell> }
                            {!isMobile && <TableCell align="center">{t('beer.yourScore')}</TableCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayBeers.map((beer) => (
                            <TableRow key={beer.name}  >  
                                
                                <TableCell 
                                    className={`${classes.td} ${classes.cellWithIcon}`} 
                                    component="th" 
                                    align="left" 
                                    scope="row"
                                >
                                    <BreweryLogoContainer breweryId={beer.brewery?.id}/>
                                    <Link  href={`/beer/${beer.id}`}>{beer.name}</Link>
                                    {beer.numReviewsByUser !== 0 && 
                                        <span className={classes.beerBadge}>
                                            <span className={classes.beerIcon}>
                                                🍻
                                            </span>
                                        </span>
                                    }
                                </TableCell>

                                
                                {!isMobile && <TableCell className={classes.td} component="th" align="center" scope="row"> <Link href={`/brewery/${beer.brewery?.id}`}>{beer.brewery?.name}</Link> </TableCell> }
                                <TableCell className={classes.td} component="th" align="center" scope="row"> <Link href={`/type/${beer.type?.id}`}>{beer.type?.name}</Link> </TableCell>
                                <TableCell className={classes.td} component="th" align="center" scope="row"> {Number(beer.abv).toFixed(2)}%</TableCell>
                                {!isMobile && <TableCell component="th" align="center" scope="row"> {beer.numTotalReviews !== 0? `${strRound(beer.globalAverageScore)}`:''} </TableCell>  }
                                {!isMobile && <TableCell component="th" align="center" scope="row"> {beer.numReviewsByUser !== 0? `${strRound(beer.averageScoreByUser)}`:''} </TableCell> }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            </Grid>
        </Grid>
    );
}

export default UserBeerDataTable;
