
import { Grid, Paper, Button, TextField, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';
import { SendResetPassword } from '../../Api/AuthenticationApi';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles({
    paper: {
      padding: '15px'
    },
    loginInput: {
      paddingBottom: '15px !important',
    },
  });
  




function ResetPassword() {
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [success, setSuccess] = useState(false);
    const {token} = useParams();

    

    const findPasswordError = ()=>{
        if(password.length < 8){return "Password must be 8 characters minimum.";}
        if(password !== password2){return "Passwords must match";}
        return ""
    }
    const handleClick = (ev)=>{
        ev.preventDefault();
        SendResetPassword(token, password).then(()=>{
            setSuccess(true);
        }).catch(()=>{

        });
    }
    return (
        <div className="">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={7}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" align="center">
                            {success?"You can now try to log into your account.":"Change your password."}
                        </Typography>
                    </Paper>
                </Grid>   

                {!success &&
                    <Grid item xs={7}>
                        <Paper className={classes.paper}>
                            <form itemRef='loginForm' onSubmit={handleClick}>
                                <TextField error={findPasswordError()!==""} value={password} className={classes.loginInput} onChange={ev=>{setPassword(ev.target.value)}} label="Password" type="password" variant="outlined" fullWidth/>
                                <TextField error={findPasswordError()!==""} helperText={findPasswordError()} value={password2} className={classes.loginInput} onChange={ev=>{setPassword2(ev.target.value)}} label="Password" type="password" variant="outlined" fullWidth/>
                                <Button color="primary" variant="contained" type="submit">Submit</Button>       
                            </form>
                        </Paper>
                    </Grid>
                }
            </Grid>
        </div>
    );
}

export default ResetPassword;
