import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NullableSlider from './NullableSlider';

function FlavorAccordion(props) {
    const { flavorIntensity, setFlavorIntensity, flavorBalance, setFlavorBalance, flavorImpression, setFlavorImpression } = props;
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography variant="h6">{t('reviewForm.accordions.taste.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={flavorIntensity} setValue={setFlavorIntensity} chapter="taste" thing="strength" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={flavorBalance} setValue={setFlavorBalance} chapter="taste" thing="balance" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={flavorImpression} setValue={setFlavorImpression} chapter="taste" thing="quality" />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('reviewForm.accordions.moreDetail')}</Typography>
                        <Box mt={2}>
                            <TextField fullWidth value={flavorDescription} onChange={ev => { setFlavorDescription(ev.target.value) }} variant="outlined" multiline rows={4} />
                        </Box>
                    </Grid> */}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default FlavorAccordion;