import {
    AppBar, Grid, Toolbar, IconButton, Button, Drawer, Badge, Popover,
    Typography, Tooltip, Slide
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@mui/styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import UserState from '../Atoms/UserAtom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { GridMenuIcon } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { GetIdentityDescriptor, GetNotifications } from '../Api/AuthenticationApi';
import Notification from './Notification';
import CookiePopup from './CookiePopup';
import logoImage from '../Assets/logo_smaller.png'
import { Link } from 'react-router-dom';


const bcbc = "black"
const bcbd = 0.5
const bcbw = 1.5



const useStyles = makeStyles((theme) => ({
    bar: {
        width: '100%',
        marginBottom: '15px',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.main,
    },
    paper: {
        padding: 15
    },
    toolBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    hrefButton: {
        zIndex: 2,
        fontFamily: '"Tilt Neon", sans-serif !important',
        color: theme.palette.secondary.contrastText,
        textDecoration: 'none',  // Remove underline
        padding: '0 1rem',  // Horizontal padding
        transition: 'color 0.3s ease',
        fontSize: '100px',
        '&:hover': {
        color: theme.palette.primary.light,
        textDecoration: 'underline'  // Add underline on hover
    },
    '&.active': {
        fontWeight: 'bold',
        textDecoration: 'underline'
    }
    },
    hrefButtonMobile: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },
    hrefButtonBold: {
        fontWeight: 'bold'
    },
    iconButton: {
        zIndex: 2,
        marginRight: theme.spacing(1),
        '&:hover': {
            boxShadow: theme.shadows[4]
        },
    },
    beerIcon: {
        zIndex: 2,
        // fontSize: '2em',  // Adjust as needed
        opacity: '100%',
        textShadow: `
        0 0 ${bcbw}px ${bcbc}, 
        -${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}, 
        -${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}
        `
    },
    snowing: {
        position: 'relative',
        overflow: 'hidden',
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '-100%',
            display: 'block',
            width: '100%',
            height: '100%',
            backgroundImage: `url('/snowflake.png')`,
            backgroundSize: '25px 25px',
            animation: `$snowfall 15s infinite linear`,
            opacity: '0.5',
        },
        '&::after': {
            backgroundSize: '50px 50px',
            animationDuration: '20s',
            animationDelay: '10s',
        },
    },
    
    snowflake: {
        position: 'absolute',
        backgroundImage: `url('/snowflake.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        animationName: `$snowfall`,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        opacity: 0.8,
        zIndex: 1,  // Ensure snowflakes are behind other AppBar contents
    },

    "@keyframes snowfall": {
        '0%': {
            transform: 'translate3d(0, -20px, 0) rotate(0deg)',  // Start just outside the AppBar
        },
        '100%': {
            transform: 'translate3d(2px, 105px, 200px) rotate(320deg)',  // End completely outside the AppBar
        },
    },
    logo: {
      // position: 'absolute',

      height: "100%",
      width:'100px',
      // width:'100%',
      minWidth:'80px'
    }
}));
  
function Navbar() {
    const { t } = useTranslation();
    const token = useRecoilValue(UserState);
    const classes = useStyles();
    const isMobile = !useMediaQuery('(min-width:700px)');
    const [menuDrawerIsOpen, setMenuDrawerIsOpen] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [identity, setIdentity] = useState(null);
    const setUser = useSetRecoilState(UserState);
  
    useEffect(() => {
        console.log(token);
        if (token == null) return;
            GetIdentityDescriptor(token).then(result => {
            setIdentity(result)
        })
            GetNotifications(token).then(result => {
            setNotifications(result);
        }).catch(error => {
            console.log("Caught a notification error...");
            setUser(null);
        });
    }, [setNotifications, token, setUser]);
  
    const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setMenuDrawerIsOpen(open);
    };
  
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
		setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    
    

    return (
      <Slide direction="down" in={true} mountOnEnter unmountOnExit>
        <AppBar position="static" className={`${classes.bar}`}>


            

        <Toolbar className={classes.toolBar}>
            {Array.from({ length: isMobile?10:25 }).map((_, index) => {
                const size = Math.random() * 10 + 15;
                return (<div
                    key={index}
                    className={classes.snowflake}
                    style={{
                        left: `${Math.random() * 100}vw`,
                        animationDuration: `${Math.random() * 2.5 + 2.5}s`,
                        animationTimingFunction: 'linear',
                        width: `${size}px`,
                        height: `${size}px`,
                        animationDelay: `${Math.random() * 3}s`,
                        top: `${ ((Math.random() * -25)) -size}px`,  // Limit initial top position
                    }}
                />
            )})}




            <Grid container justifyContent="space-between" alignItems="center">
              
              
            <Grid item xs={1}>
              <IconButton 
                size="large" 
                edge="start"
                color="inherit" 
                aria-label="menu"
                sx={{ mr: 2 }}
                // className={classes.beerIcon} // Remove if using sx for styling
                component={Link} // Use this if you're using react-router
                to="/" // Use this if you're using react-router
                // href="/" // Use this for a traditional link (not recommended with React Router)
              >
                <img src={logoImage} className={classes.logo} alt="logo" />
              </IconButton>
            </Grid>

                <Grid item xs={1}>
                    <CookiePopup />
                </Grid>
              

              {!isMobile && (
                <Grid item xs={8}>
                    <Button variant='link' className={classes.hrefButton} href="/about">{t('menu.aboutUs')}</Button>
                    <Button variant='link' className={classes.hrefButton} href="/shoppinglist">{t('menu.shoppingList')}</Button>
                    {token !== null && <Button variant='link' className={classes.hrefButton} href="/groups">{t('menu.groups')}</Button>}
                    {token !== null && <Button variant='link' className={classes.hrefButton} href="/events">{t('menu.events')}</Button>}
                </Grid>
              )}
  
              {notifications !== null &&
                <Grid item xs={1}>
                  <IconButton onClick={handleClick}>
                    <Badge color="secondary" badgeContent={notifications.length} max={999}>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Grid container className={classes.paper}>
                      {notifications.length === 0 && <Typography>{t('menu.noNotifications')}...</Typography>}
                      {notifications.length !== 0 &&
                        notifications.map(notification => <Notification notification={notification} />)
                      }
                    </Grid>
                  </Popover>
                </Grid>
              }
  
              {!isMobile &&
                <Grid item xs={1}>
                  {token === null && (
                    <Tooltip title={t('menu.login')}>
                      <IconButton className={classes.iconButton} href="/login">
                        🔑
                      </IconButton>
                    </Tooltip>
                  )}
                  {token !== null && (
                    <Tooltip title={t('menu.profile')}>
                      <IconButton className={classes.iconButton} href="/profile">
                        <AccountCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(identity !== null && identity.isAdministrator) && (
                    <Tooltip title={t('menu.adminPanel')}>
                      <IconButton className={classes.iconButton} href="/admin">
                        <AdminPanelSettingsIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              }
  
              {isMobile && <Grid item xs={3}>
                <Button onClick={toggleDrawer(true)}><GridMenuIcon /></Button>
                <Drawer
                  anchor='right'
                  open={menuDrawerIsOpen}
                  onClose={toggleDrawer(false)}
                  className={classes.drawer}
                >
                  {token !== null && <Button color="primary" variant="link" className={classes.hrefButtonMobile} href="/groups">{t('menu.groups')}</Button>}
                  {token !== null && <Button color="primary" variant="link" className={classes.hrefButtonMobile} href="/events">{t('menu.events')}</Button>}
                  <Button color="primary" variant="link" className={classes.hrefButtonMobile} href="/shoppinglist">{t('menu.shoppingList')}</Button>
                  <Button color="primary" variant="link" className={classes.hrefButtonMobile} href="/about">{t('menu.aboutUs')}</Button>
                  {token === null && <Button color="primary" className={classes.hrefButtonMobile} align="right" href="/login">🔑{t('menu.login')}</Button>}
                  {token !== null && <Button className={classes.hrefButtonMobile} align="right" href="/profile"><AccountCircleIcon /></Button>}
                  {(token !== null && token.administrator) && <Button className={classes.hrefButton} align="right" href="/admin"><AdminPanelSettingsIcon /></Button>}
                </Drawer>
              </Grid>}
            </Grid>
          </Toolbar>
        </AppBar>
      </Slide>
    );
  }
  
  export default Navbar;