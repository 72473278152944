import { useState, useEffect } from 'react';
import { CircularProgress, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { GetBeer, GetReviewsForBeer } from '../Api/BeerApi';
import BeerInfo from '../Components/InfoComponents/BeerInfo';
import ReviewForm from '../Components/ReviewForm';
import ReviewListItem from '../Components/ReviewListItem';
import { useRecoilValue } from 'recoil';
import UserState from '../Atoms/UserAtom';
import { a11yProps, TabPanel } from '../Components/TabPanel';
import BeerStatistics from './Beer/BeerStatistics';
import { makeStyles } from '@mui/styles';
import { GetIdentityDescriptor } from '../Api/AuthenticationApi';

const useStyles = makeStyles((theme) => {
    return {
        foo: {background: console.log(theme)},
        root: {
        },
        loadingContainer: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
        },
        tabs: {
            backgroundColor: theme.palette.secondary.main, // Amber Ale
            color: theme.palette.secondary.contrastText, // Brew Black
        },
        tabLabel: {
            color: theme.palette.secondary.main, // Golden Hops
        },
        activeTabLabel: {
            backgroundColor: 'red',
            color: theme.palette.primary.light,   // Golden Hops
            fontWeight: 'bold',   // making the active tab's text bold
        },
    };
});

const useFetch = (id, setBeer, setReviews) => {
    useEffect(() => {
        GetBeer(id)
        .then(setBeer).catch(error => {
            console.error('Failed fetching beer:', error);
        });
        GetReviewsForBeer(id)
        .then(setReviews).catch(error => {
            console.error('Failed fetching reviews:', error);
        });
    }, [id, setBeer, setReviews]);
}

function BeerProfile(params) {
    const { id } = useParams();
    const classes = useStyles();
    const [beer, setBeer] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [value, setValue] = useState(0);
    const token = useRecoilValue(UserState);
    const [identity, setIdentity] = useState(null);
    useEffect(() => {
        console.log(token);
        if (token == null) return;
        GetIdentityDescriptor(token).then(result => {
          console.log(result);
          setIdentity(result)
        })
    }, [token]);

    useFetch(id, setBeer, setReviews);

    return (
        <Grid container justifyContent="center" spacing={3} className={classes.root}>
            <Grid item xs={12} md={8}>
                {beer ? (
                    <BeerInfo beer={beer} />
                ) : (
                    <Paper className={classes.loadingContainer}>
                        <CircularProgress size={50} />
                    </Paper>
                )}

                {beer && identity && <ReviewForm beerId={id} token={token} beerName={beer.name} />}
                
                <Tabs
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    variant="fullWidth"
                    aria-label="Beer tabs" // Highlight color beneath the active tab
                    textColor="inherit"         // Text color of the active tab
                    className={classes.tabs}
                >
                    
                    <Tab 
                        label="Reviews" 
                        {...a11yProps(0)} 
                        className={value === 0 ? classes.activeTabLabel : classes.tabLabel} 
                    />
                    <Tab 
                        label="Statistics" 
                        {...a11yProps(1)} 
                        className={value === 1 ? classes.activeTabLabel : classes.tabLabel} 
                    />

                </Tabs>

                <TabPanel value={value} index={0}>
                    {reviews ? (
                        reviews.map(review => <ReviewListItem identity={identity} key={`id: ${review.id}`} review={review} />)
                    ) : (
                        <Typography variant="body1" align="center">Loading reviews...</Typography>
                    )}
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <BeerStatistics beerId={id} />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default BeerProfile;