import { Button, Grid, Paper, Tab, Tabs } from '@mui/material';
import UserState from '../../Atoms/UserAtom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TabPanel, a11yProps } from '../../Components/TabPanel';
import { useEffect, useState } from 'react';
import UserStatistics from './UserStatistics';
import { makeStyles } from '@mui/styles';
import { GetIdentityDescriptor } from '../../Api/AuthenticationApi';
import UserProfileHomeTab from './UserProfileHomeTab';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main + '70',
    borderRadius: "20px 20px 0px 0px"
  }
}));

function Profile() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const token = useRecoilValue(UserState);
  const [identity, setIdentity] = useState(null);

  const setUser = useSetRecoilState(UserState);

  useEffect(() => {
    GetIdentityDescriptor(token).then(result => {
        setIdentity(result);
    }).catch(error => {
        console.error(error);
    });
  }, [token]);

    const handleLogout = () => {
        localStorage.removeItem('user');
        setUser(null);
        window.location.replace('/');
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabStyles = (index) => value === index ? classes.activeTab : null;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab label="Profile" className={tabStyles(0)} {...a11yProps(0)} />
                    <Tab label="Account Control" className={tabStyles(1)} {...a11yProps(1)} />
                    <Tab label="Statistics" {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <UserProfileHomeTab identity={identity}/>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            {identity && <Grid item xs={6}>{identity.username}</Grid>}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={handleLogout}>Logout</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <UserStatistics />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default Profile;
