// import logo from './logo.svg';
// import './App.css';

import { Authenticate } from '../../Api/AuthenticationApi'; 
import { Button, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserState from '../../Atoms/UserAtom';
import { useSetRecoilState } from 'recoil';
import { useState } from 'react';

const useStyles = makeStyles({
  paper: {
    padding: '15px'
  },
  loginInput: {
    paddingBottom: '15px !important',
  },
});
function Login() {
  const setUser = useSetRecoilState(UserState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const [loginError, setLoginError] = useState("");
  const handleClick =(ev)=>{
    ev.preventDefault();
    Authenticate(email, password).then(data=>{
      console.log(data);
      setUser(data);
      localStorage.setItem(
        'user',
        data.token
      )
      window.location='/'

    }).catch(error=>{
      //display error!
      setLoginError(error.details);
    })
  }

  return (
    <div className="">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <Typography variant="h5" align="center">
              Sign into smakkid.is
            </Typography>
            <Typography variant="h5" align="center">
              Don't have an account? <Link href="register">Register</Link>
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <form itemRef='loginForm' onSubmit={handleClick}>
              <TextField error={loginError!==''} helperText={loginError} value={email} className={classes.loginInput} onChange={ev=>{setEmail(ev.target.value)}} label="Email" variant="outlined" fullWidth/>
              <TextField value={password} className={classes.loginInput} onChange={ev=>{setPassword(ev.target.value)}} label="Password" type="password" variant="outlined" fullWidth/>
              <Button color="primary" type="submit">Submit</Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <Typography variant="caption">Forgot your password? <Link href="/account/reset">Reset it here!</Link> </Typography>
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}

export default Login;
