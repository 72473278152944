import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';


const bcbc = "black"
const bcbd = 0.5
const bcbw = 1.5



const useStyles = makeStyles((theme) => {return {
    cookieButton: {
        backgroundColor: theme.palette.secondary.main,//'#ffd700', // Golden color for the cookie button
        '&:hover': {
            backgroundColor: '#ffe066' // Slightly lighter on hover
        },
        transition: '0.3s', // Smooth transition effect
        textShadow: `
        0 0 ${bcbw}px ${bcbc}, 
        -${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}, 
        -${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px ${bcbw}px ${bcbd}px ${bcbc}, 
        ${bcbw}px -${bcbw}px ${bcbd}px ${bcbc}
        `
        // boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)' // Slight shadow for depth
    },
    dialogContentText: {
        lineHeight: '1.5',
        fontSize: '1.1rem'
    }
}});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CookiePopup() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.foo}>
            <Button
                
                className={classes.cookieButton}
                onClick={handleClickOpen}
            >
                🍪
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("cookieTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className={classes.dialogContentText}
                    >
                        {t('cookieDisclaimer')}
                        {/* Við notum Google Analytics til að mæla notkunarmynstur, einnig notum við vafrakökur til að halda utan um aðgangsupplýsingar. Engin lykilorð eru vistuð í "plaintext" hjá okkur, og einungis eru notuð svokölluð "jwt" aðganslyklar til að auðkenna þig sem notanda. */}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}