import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GetBreweryProfilePic } from "../../Api/BeerApi";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    _breweryLogoContainer: {
        width: '50px',  // Adjust as needed
        height: '50px',  // Adjust as needed
        marginRight: '10px',  // Space between the logo and the beer name
        display: 'inline-block',
        verticalAlign: 'middle',  // Aligns the logo with the middle of the text
        borderRadius: '50%',  // Makes the container circular
        overflow: 'hidden',  // Ensures that the image doesn't spill out of the circular container
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }
}));

const BreweryLogoContainer = ({ breweryId }) => {
    const [breweryLogo, setBreweryLogo] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        GetBreweryProfilePic(breweryId).then(result => {
            // Assuming result is the base64 encoded string
            setBreweryLogo(`${result}`);
        }).catch(error => {
            console.error('Error fetching image:', error);
        });
    }, [breweryId]);

    return (
        <>
            {breweryLogo ? (
                <img
                    className={classes._breweryLogoContainer}
                    src={breweryLogo}
                    alt="Brewery Logo"
                />
            ) : (
                <CircularProgress />
            )}
        </>
    );
};
export default BreweryLogoContainer;