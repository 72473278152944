import { Button, Divider, FormHelperText, Grid, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react'
import { useRecoilValue } from 'recoil';
import { EditGroup, InviteToGroup } from '../Api/GroupApi';
import UserState from '../Atoms/UserAtom';
import ImageInput from './ImageInput';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';


const useStyles = makeStyles({
    inputContainer: {
        paddingBottom: '15px',
    },
    paper: {
        padding: '15px',
        backgroundColor: '#f7f9fa',
    },
    section: {
        marginBottom: '20px',
    },
    icon: {
        marginRight: '10px',
    }
});

const GroupProfileAdministrationPanel = ({ group }) => {
    const token = useRecoilValue(UserState);
    const [inviteName, setInviteName] = useState("");
    const classes = useStyles();
    const [editedGroup, setEditedGroup] = useState(group)
    const handleInviteButton = async () => {
        try {
            await InviteToGroup(token, group.id, inviteName);
            // Maybe reset the inviteName or show a success message
            setInviteName('');
        } catch (error) {
            console.error('Failed to invite:', error);
            // Maybe show an error message to the user
        }
    }
    console.log(group);

    const handleEdit = () => {
        EditGroup(token, editedGroup).then(()=>{
            window.location.reload()
        })
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="h6">Group Administration</Typography>
            <Divider className={classes.section} />

            {/* Invite User Section */}
            <Typography variant="subtitle1" gutterBottom>
                <PersonAddIcon className={classes.icon} fontSize="inherit" /> Invite User
            </Typography>
            <Grid container spacing={2} className={classes.section}>
                <Grid item xs={12}>
                    <TextField
                        label="Username"
                        className="form-control"
                        fullWidth
                        value={inviteName}
                        onChange={ev => setInviteName(ev.target.value)}
                        variant="outlined"
                        aria-describedby="inviteUserHelper"
                    />
                    <FormHelperText id="inviteUserHelper">Enter the username of the person you'd like to invite.</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleInviteButton}>
                        Invite
                    </Button>
                </Grid>
            </Grid>
            <Divider className={classes.section} />

            {/* Edit Group Section */}
            <Typography variant="subtitle1" gutterBottom>
                <EditIcon className={classes.icon} fontSize="inherit" /> Edit Group Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Group Name"
                        className="form-control"
                        fullWidth
                        value={editedGroup.title}
                        onChange={ev => setEditedGroup(prev => ({...prev, title:ev.target.value}))}
                        variant="outlined"
                        aria-describedby="editGroupNameHelper"
                    />
                    <FormHelperText id="editGroupNameHelper">Update the name of the group.</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <ImageInput onImageChange={(base64Image) => setEditedGroup(prev => ({ ...prev, b64CoverImage: base64Image }))} />
                    <FormHelperText id="groupImageHelper">Update the group's cover image.</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleEdit}>
                        Update Group
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default GroupProfileAdministrationPanel;
