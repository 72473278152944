import React, { useState, useEffect } from 'react';
import { GetBeers, GetBreweryList, GetTypeList } from '../../Api/BeerApi';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Typography, Box, Paper, Divider, Card, CardContent, Avatar, Grid } from '@mui/material';
import { AddBeerApiCall } from '../../Api/AdminApi';
const AddBeer = ({token}) => {

    const [beerName, setBeerName] = useState('');
    const [beerTypeId, setBeerTypeId] = useState('');
    const [breweryId, setBreweryId] = useState(''); // Changed from description
    const [breweries, setBreweries] = useState([]);
    const [beerTypes, setBeerTypes] = useState([]); 
    const [alcoholByVolume, setAlcoholByVolume] = useState(0); 
    const [beers, setBeers] = useState([])
    useEffect(() => {
        GetBreweryList().then(data => setBreweries(data));
        GetTypeList().then(data => setBeerTypes(data));
        GetBreweryList().then(data => setBreweries(data));
        GetBeers().then(beers => setBeers(beers));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(`Submitting ${beerName} (${beerTypeId})`);
        AddBeerApiCall(token, {name: beerName, manufacturerId: breweryId, typeId: beerTypeId, abv: alcoholByVolume})
        .then(async ()=>{
            GetBeers().then(beers => setBeers(beers))
        })
        // handle form submission logic here
    };

    console.log(breweryId);

    return (
        <Box component="div" p={3}>
            <Paper elevation={3} style={{ padding: '24px' }}>
                <Typography variant="h4" gutterBottom>
                    Add a Beer
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Beer Name"
                            variant="outlined"
                            value={beerName}
                            onChange={(e) => setBeerName(e.target.value)}
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="brewery-label">Brewery</InputLabel>
                            <Select
                                labelId="brewery-label"
                                id="brewery"
                                value={breweryId}
                                onChange={(e) => setBreweryId(e.target.value)}
                                label="Brewery"
                            >
                                {breweries.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="beerType-label">Beer Type</InputLabel>
                            <Select
                                labelId="beerType-label"
                                id="beerType"
                                value={beerTypeId}
                                onChange={(e) => setBeerTypeId(e.target.value)}
                                label="Beer Type"
                            >
                                {beerTypes.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                id="beerABV" label="ABV" type="number" inputProps={{ 
                                    step: "0.1", 
                                    min: "0", 
                                    max: "100" 
                                }} // allows decimals and restricts range from 0 to 100
                                variant="outlined"
                                value={alcoholByVolume}
                                onChange={(e) => setAlcoholByVolume(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Button variant="contained" color="primary" type="submit">
                        Add Beer
                    </Button>
                </form>
            </Paper>
            <Box mt={3}>
        <Typography variant="h5" gutterBottom>
            List of Beers
        </Typography>
        {beers.map((beer, index) => (
            <Card key={beer.id} variant="outlined" style={{ marginBottom: '16px' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1}>
                            <Avatar variant="rounded" src={beer.brewery.b64Logo} alt={`${beer.brewery.name} logo`} />
                        </Grid>
                        <Grid item xs={11} container spacing={1}>
                            <Grid item xs={3}>
                                <Typography variant="body1">{beer.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1">{beer.brewery.name}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">{beer.abv}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1">{beer.typeName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                {(index !== beers.length - 1) && <Divider />}
            </Card>
        ))}
    </Box>
        </Box>
    );
};
export default AddBeer;
    
    

