import { Add, LocalDrink, Remove } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function BeerNumberInput(props) {
    const { number, setNumber } = props;
    const MAX_BEERS = 20;

    const handleNumberChange = (increment) => {
        const newNumber = number + increment;
        if (newNumber < 1 || newNumber > MAX_BEERS) return;
        setNumber(newNumber);
    };

    const { t } = useTranslation();

    return (
        <Card elevation={3} style={{ background: '#f5f5f5' }}>
            <CardContent>
                <Typography variant="subtitle1" gutterBottom align="center">
                    {t('reviewForm.numberOfBeer')}:
                </Typography>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Tooltip title={t('Decrease count')}>
                            <IconButton onClick={() => handleNumberChange(-1)} color="primary" aria-label="decrease beer count">
                                <Remove />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item container direction="column" alignItems="center" xs={6}>
                        <Typography variant="h4" color="primary">{number}</Typography>
                        <LocalDrink color="primary" style={{ fontSize: 40, margin: '4px' }} />
                        {number >= 10 && <Typography variant="caption" color="error">Easy there, champ! 😨</Typography>}
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('Increase count')}>
                            <IconButton onClick={() => handleNumberChange(1)} color="primary" aria-label="increase beer count">
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {number !== null && (
                    <Button variant="contained" color="secondary" onClick={() => setNumber(null)} style={{ marginTop: '16px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        Clear
                    </Button>
                )}
            </CardContent>
        </Card>
    );
}

export default BeerNumberInput;