import NullableSlider from './NullableSlider';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function AromaAccordion(props) {
    const { t } = useTranslation();
    const { aromaIntensity, setAromaIntensity, aromaBalance, setAromaBalance, aromaImpression, setAromaImpression } = props;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography variant="h6">{t('reviewForm.accordions.aroma.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '24px' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={aromaIntensity} setValue={setAromaIntensity} chapter="aroma" thing="strength" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={aromaBalance} setValue={setAromaBalance} chapter="aroma" thing="balance" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NullableSlider value={aromaImpression} setValue={setAromaImpression} chapter="aroma" thing="quality" />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('reviewForm.accordions.moreDetail')}</Typography>
                        <Box mt={2}>
                            <TextField fullWidth value={aromaDescription} onChange={ev => { setAromaDescription(ev.target.value) }} variant="outlined" multiline rows={4} />
                        </Box>
                    </Grid> */}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default AromaAccordion;