import settings from './apiSettings';

// export function AddCsvFile(token, fragment, file){
//     let formData = new FormData();
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
    
//     reader.onload = () => {

//         const base64String = reader.result.split(',')[1];
//         const headers = new Headers();
//         headers.append('Content-Type', 'application/json');
//         headers.append("Authorization", `Bearer ${token}`);

//         return fetch(`${settings.host}/Admin/${fragment}`, {
//             method: 'POST',
//             headers: headers,
//             body: JSON.stringify({ file: base64String })
//         }).then(async response=>{
//             return await response.json()
//         });
//     }
// }
// export function AddCsvFile(token, fragment, file){
//     let formData = new FormData();
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
    
//     reader.onload = () => {
//         const base64String = reader.result.split(',')[1];
//         const headers = new Headers();
//         headers.append('Content-Type', 'application/json');
//         headers.append("Authorization", `Bearer ${token}`);

//         // Return the fetch call as a promise
//         return new Promise((resolve, reject) => {
//             fetch(`${settings.host}/Admin/${fragment}`, {
//                 method: 'POST',
//                 headers: headers,
//                 body: JSON.stringify({ file: base64String })
//             })
//             .then(async response => {
//                 const json = await response.json();
//                 resolve(json);
//             })
//             .catch(error => {
//                 reject(error);
//             });
//         });
//     }
// }

// export function GetGroupFeed(token, groupId){
//     return fetch(`${settings.host}/Groups/${groupId}/feed`, {
//         headers: {'authorization':`bearer ${token}`}
//     }).then(async response=>{
//         return await response.json()
//     });
// }

export function GetUsers(token) {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${settings.host}/Administration/Users`, {
        method: 'GET',
        headers: headers
    }).then(async(x) => {
        return await x.json();
    });
}

export async function AddBeerApiCall(token, beer) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${settings.host}/Administration/Beer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify( beer )
    });
}   

export async function AddManufacturerApiCall(token, manufacturer) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${settings.host}/Administration/Manufacturer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify( manufacturer )
    });
}

export async function UpdateBrewery (token, brewery){
    return fetch(`${settings.host}/Administration/Manufacturer`,
    {
        method: 'PUT', 
        // mode: 'cors',
        headers: { 
            'Content-Type': 'application/json',
            'authorization':`bearer ${token}`
        }, 
        body: JSON.stringify(brewery)
    }
    ).then(async (response)=>{
        if(!response.ok){
            throw await response.json();
        }
    })
}

export async function AddCountryApiCall(token, country) {
    const headers = new Headers();
    console.log(token);
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${settings.host}/Administration/Country`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify( country )
    });
}

export async function AddTypeApiCall(token, type) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`${settings.host}/Administration/Type`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify( type )
    });
}

export async function AddCsvFile(token, fragment, file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
        reader.onload = async () => {
            try {
                const base64String = reader.result.split(',')[1];
                const headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append("Authorization", `Bearer ${token}`);

                const response = await fetch(`${settings.host}/Admin/${fragment}`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({ file: base64String })
                });

                const json = await response.json();
                resolve(json);
            } catch (error) {
                reject(error);
            }
        }
    });
}

