import { Grid, Paper, Typography, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    },
    paper: {
        padding: theme.spacing(3),
        color: '#AEEFF0',
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
        borderRadius: '15px',
        boxShadow: '0 3px 5px 2px rgba(25, 133, 161, 0.3)',
        transition: 'transform 0.2s',
        '@media (max-width:600px)': {
            padding: '15px',
        }
    },
    heading: {
        
        color: '#AEEFF0',
        textShadow: '0 0 8px #66E0DD',
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: `${theme.spacing(4)} !important`,
        '@media (max-width:600px)': {
            fontSize: '1.5rem',
        }
    },
    subheading: {
        
        color: '#AEEFF0',
        textShadow: '0 0 8px #66E0DD',
        opacity: 0.8,
        fontWeight: 600,
        marginBottom: theme.spacing(1.5),
        '@media (max-width:600px)': {
            fontSize: '0.9rem',
        } 
    },
    content: {
        marginBottom: `${theme.spacing(3)} !important`,
        color: theme.palette.primary.contrastText,
        // marginBottom: '0px'
    },
}));


function AboutPage() {
    const classes = useStyles();

    useEffect(() => {
        // Any side effects can be handled here
    }, []);

    return (
        <Zoom in={true}>
        <Grid container justifyContent="center" className={classes.root}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography align="center" variant="h1" className={classes.heading}>Hey, this is Smakkið!</Typography>
                    
                    <Typography align="left" variant="h3" className={classes.subheading}>So, what's the deal?</Typography>
                    <Typography align="left" variant="body1" className={classes.content}>
                        Back in 2020, some pals and I started jotting down our favorite beers in a spreadsheet.<br />Who knew?<br />That list has grown up, and now it's this whole thing called Smakkid.is. It's our cozy little spot for beer fans.
                    </Typography>
                    
                    <Typography align="left" variant="h3" className={classes.subheading}>Got a thing for Christmas brews?</Typography>
                    <Typography align="left" variant="body1" className={classes.content}>
                        Us too! We can't help but geek out over holiday ales.<br />Come on in, find some cool brews, chat with other fans, and maybe discover your next winter go-to.
                    </Typography>
                    
                    <Typography align="left" variant="h3" className={classes.subheading}>But wait, there's more...</Typography>
                    <Typography align="left" variant="body1" className={classes.content}>
                        Beyond the Christmas vibe, there's a whole world of beers out there.<br />Seasons change, and so do the flavors. We want to eventually be here for all of it, glass in hand.
                    </Typography>
                    
                    <Typography align="left" variant="h3" className={classes.subheading}>Share your thoughts!</Typography>
                    <Typography align="left" variant="body1" className={classes.content}>
                        Smakkid's more than just a website. It's our beer-loving fam.<br /> Hop in, flex your tastebuds, and let's have a good time together.
                    </Typography>
                    <Typography align="left" variant="h4" className={classes.subheading}>Want to be in contact?</Typography>
                    <Typography align="left" variant="body2" className={classes.content}>smakkid@smakkid.is</Typography>
                    {/* Link to mailto somehow, the address is smakkid@smakkid.is */}
                </Paper>
            </Grid>
        </Grid>
    </Zoom>
    );
}

export default AboutPage;
