import { Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetTypeBeerList, GetTypeBeerListForUser } from '../Api/BeerApi';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import UserState from '../Atoms/UserAtom';
import UserBeerDataTable from '../Components/DataTables/UserBeerDataTable';
import BeerDataTable from '../Components/DataTables/BeerDataTable';

function TypeProfile() {
    
    const {id} = useParams();
    
    const [beers, setBeers] = useState(null);

    
    const token = useRecoilValue(UserState);
    useEffect(() => {
        if(token){
            GetTypeBeerListForUser(id, token).then(result=>{
                setBeers(result);
            }).catch(error=>{
            });
        } else {
            GetTypeBeerList(id).then(result=>{
                setBeers(result);
            }).catch(error=>{
            });
        }
    }, [token, id, setBeers])

    return (
        <Grid container justifyContent="center">
            <Paper>
                
                {token && beers && <UserBeerDataTable beers={beers}/>}
                {!token && beers && <BeerDataTable beers={beers}/>}
            </Paper>
        </Grid>
    );
}

export default TypeProfile;
