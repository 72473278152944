
import { Grid, Paper, Button, TextField, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { SendPasswordResetRequest } from '../../Api/AuthenticationApi';

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const useStyles = makeStyles({
    paper: {
      padding: '15px'
    },
    loginInput: {
      paddingBottom: '15px !important',
    },
});

function RequestPasswordReset() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);

    const handleClick = (ev)=>{
        ev.preventDefault();
        SendPasswordResetRequest(email).then(()=>{
            setSuccess(true);
        }).catch(()=>{

        })
        console.log(email);
    }

    return (
        <div className="">
            <Grid container spacing={2} justifyContent="center">
            <Grid item xs={7}>
                <Paper className={classes.paper}>
                <Typography variant="h5" align="center">
                    {!success ? "Request password reset." : "An email has been sent with a link to reset your password." }
                </Typography>
                
                </Paper>
            </Grid>
            {!success &&
                <Grid item xs={7}>
                    <Paper className={classes.paper}>
                        <form itemRef='passwordResetRequest' onSubmit={handleClick}>
                            <TextField error={!isValidEmail(email)} helperText={isValidEmail(email)?"":"Email is not valid"} 
                                value={email} className={classes.loginInput} 
                                onChange={ev=>{setEmail(ev.target.value)}} 
                                type="email" label="Email" variant="outlined" fullWidth />
                            <Button variant="contained" color="primary" type="submit" disabled={!isValidEmail(email)}>Submit</Button>
                        </form>
                    </Paper>
                </Grid>
            }
            </Grid>
        </div>
    );
}

export default RequestPasswordReset;
